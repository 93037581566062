import { ConfirmationCommonService } from '@aca-new/app/pages/book/pages/confirmation/shared/services/confirmation-common.service';
import { PayByTransferComponent } from '@aca-new/app/pages/payments/shared/components/pay-by-transfer/pay-by-transfer.component';
import { AuditPaymentService } from '@aca-new/app/pages/payments/shared/services/audit/audit-payment.service';
import { InspectionPaymentService } from '@aca-new/app/pages/payments/shared/services/inspection/inspection-payment.service';
import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PayByTransferService {
  public constructor(
    private readonly _dialog: DialogService,
    private readonly _inspectionPaymentService: InspectionPaymentService,
    private readonly _auditPaymentService: AuditPaymentService,
    private readonly _confirmationCommonService: ConfirmationCommonService
  ) {}

  public openPayByTransfer(data: Record<string, unknown>): void {
    if (!data.orderId) {
      return;
    }

    const bookType = data.bookType as string;
    const dialogRef: DialogRef<string> = this._dialog.open(PayByTransferComponent, {
      data: {
        orderId: data.orderId,
        paymentService: bookType === 'inspection' ? this._inspectionPaymentService : this._auditPaymentService,
      },
      stopCloseWhenClickBackdrop: true,
    });

    dialogRef
      .afterClosed$()
      .pipe(untilDestroyed(this))
      .subscribe((): void => {
        this._confirmationCommonService.updateGetOrderDetails();
      });
  }
}
