import { IReflowForm, IReflowMaterial } from '@aca-new/app/pages/reflow/shared/models/interfaces/reflow-form.interface';
import { IReflowOrderPrice } from '@aca-new/app/pages/reflow/shared/models/interfaces/reflow-order-price.interface';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { IPostMessageData } from '@aca-new/app/shared/interfaces/post-message.interface';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { QimaNullableType } from '@aca-new/app/shared/models/types/qima.type';
import { AppLocationService } from '@aca-new/app/shared/services/browser-services/app-location/app-location-service';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { AppPostMessageService } from '@aca-new/app/shared/services/post-message-services/app-post-message/app-post-message.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ReflowService {
  public constructor(
    private readonly _storageService: StorageService,
    private readonly _httpService: HttpService,
    private readonly _appPostMessageService: AppPostMessageService,
    private readonly _appLocationService: AppLocationService,
    @Inject(WINDOW) private readonly _window: IMyWindow
  ) {}

  public calculateReflowOrderPrice$(data: IReflowForm): Observable<QimaNullableType<IReflowOrderPrice>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .post<IHttpResponseBody<QimaNullableType<IReflowOrderPrice>>>(`${SERVER_URL}/user/${userId}/reflow/calculate`, data, {
        observe: 'response',
      })
      .pipe(
        map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<IReflowOrderPrice>>>>): QimaNullableType<IReflowOrderPrice> => response?.body?.content || null)
      );
  }

  public cancelReflowOrder$(orderId: string): Observable<void> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .delete<void>(`${SERVER_URL}/user/${userId}/reflow/cancel/${orderId}`, {
        observe: 'body',
      })
      .pipe(map((response: void): void => response));
  }

  public saveReflowOrder$(data: IReflowOrderPrice): Observable<QimaNullableType<string>> {
    // TODO should be stored in user profile or user service
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .post<IHttpResponseBody<QimaNullableType<string>>>(`${SERVER_URL}/user/${userId}/reflow`, data, {
        observe: 'response',
      })
      .pipe(map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<string>>>>): QimaNullableType<string> => response?.body?.content || null));
  }

  public getMaterials$(): Observable<IReflowMaterial[]> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const reflowMaterials: string | null = this._storageService.getItem(EStorageKeys.REFLOW_MATERIALS);

    if (reflowMaterials) {
      return of(JSON.parse(reflowMaterials));
    }

    return this._httpService.httpClient
      .get<IHttpResponseBody<IReflowMaterial[]>>(`${SERVER_URL}/user/${userId}/reflow/materials`, {
        observe: 'body',
      })
      .pipe(
        map((response: IHttpResponseBody<IReflowMaterial[]>): IReflowMaterial[] => {
          this._storageService.setItem(EStorageKeys.REFLOW_MATERIALS, JSON.stringify(response.content || []));

          return response.content || [];
        })
      );
  }

  public postMessageToACA(msg: IPostMessageData): void {
    const targetWindow = this._window.parent;

    if (targetWindow) {
      const targetOrigin = this._appLocationService.getAcaUrl();

      this._appPostMessageService.post(targetWindow, msg, targetOrigin);
    }
  }
}
