import { ICurrencyTableCell } from '@aca-new/app/shared/components/currency-table-cell/shared/models/interfaces/currency-table-cell.interface';
import { ICustomizedTableCellComponent } from '@aca-new/app/shared/components/table/shared/components/customized-table-cell/shared/interfaces/customized-table-cell.interface';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-currency-table-cell',
  templateUrl: './currency-table-cell.component.html',
  styleUrls: ['./currency-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyTableCellComponent implements ICustomizedTableCellComponent {
  public data!: ICurrencyTableCell;
}
