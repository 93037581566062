import { IMAGES } from '@aca-new/app/shared/constants/images.constants';
import { ClipboardDirective } from '@aca-new/app/shared/directives/clipboard/clipboard.directive';
import { QimaTooltipService } from '@aca-new/app/shared/services/exported-services/qima-tooltip/qima-tooltip.service';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QimaIconModule, QimaTooltipDirectiveModule } from '@qima/ngx-qima';

@Component({
  selector: 'app-clipboard',
  templateUrl: './clipboard.component.html',
  styleUrls: ['./clipboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [QimaIconModule, QimaTooltipDirectiveModule, TranslateModule, ClipboardDirective, NgClass],
})
export class ClipboardComponent {
  /**
   * @description
   * The text to be copied
   * @type {string}
   * @default ''
   */
  @Input('clipboardTextToCopy')
  public textToCopy: string = '';

  public clipboardIcon: string = IMAGES.clipboardIcon;
  public isCopied: boolean = false;
  public constructor(public readonly qimaTooltip: QimaTooltipService) {}
  public onMouseChange(): void {
    this.isCopied = false;
  }

  public onCopySuccess(): void {
    this.isCopied = true;
  }
}
