import { CurrencyViewComponent } from '@aca-new/app/shared/components/currency-view/currency-view.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QimaAutoTooltipDirectiveModule } from '@qima/ngx-qima';

@NgModule({
  declarations: [CurrencyViewComponent],
  exports: [CurrencyViewComponent],
  imports: [CommonModule, QimaAutoTooltipDirectiveModule],
})
export class CurrencyViewModule {}
