export enum EDocType {
  ACCESS_MAP = 'ACCESS_MAP',
  BUS_LIC = 'BUS_LIC',
  CERT_ORIGIN = 'CERT_ORIGIN',
  CHECKLIST_DEFECT = 'CHECK_LIST_DEFECT',
  CHECKLIST_TEST = 'CHECK_LIST_TEST',
  CUST_ATT = 'CUST_ATT',
  ENTITY_PHOTO = 'ENTITY_PHOTO',
  EXPORT_LIC = 'EXPORT_LIC',
  EXTERNAL_DOCUMENT = 'EXTERNAL_DOCUMENT',
  FAC_AUDIT_REPORT = 'FAC_AUDIT_REPORT',
  ISO_CERT = 'ISO_CERT',
  ORDER_ATT = 'ORDER_ATT',
  OTHER_DOC = 'OTHER_DOC',
  PROD_FINAL_REPORT = 'PROD_FINAL_REPORT',
  PROD_REPORT_PICTURE = 'PROD_REPORT_PICTURE',
  REPORT_IMAGE_ZIP = 'REPORT_IMAGE_ZIP',
  ROHS_CERT = 'ROHS_CERT',
  TAX_CERT = 'TAX_CERT',
  USER_COMMENT_ATTACHMENT = 'USER_COMMENT_ATTACHMENT',
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  TEST_REPORT = 'TEST_REPORT',
}
