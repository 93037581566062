import { ErrorPageComponent } from '@aca-new/app/shared/components/error-page/error-page.component';
import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { DialogHeaderModule } from '@aca-new/app/shared/components/modal/shared/components/dialog-header/dialog-header.module';
import { DIALOG_DATA } from '@aca-new/app/shared/components/modal/shared/tokens/dialog.token';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

interface IErrorPage {
  description: string;
  image: string;
  isGoBackHomeVisible: boolean;
  title: string;
}

@Component({
  selector: 'app-error-page-dialog',
  templateUrl: './error-page-dialog.component.html',
  styleUrls: ['./error-page-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DialogHeaderModule, ErrorPageComponent, TranslateModule],
})
export class ErrorPageDialogComponent {
  public constructor(
    @Inject(DialogRef) private readonly _dialogRef: DialogRef<boolean>,
    @Inject(DIALOG_DATA) public readonly dialogData: IErrorPage
  ) {}

  public close(): void {
    this._dialogRef.close(false);
  }

  public onGoBackHomeClick(): void {
    this._dialogRef.close(true);
  }
}
