import { REPORTS_STATUS } from '@aca-new/app/pages/reports/shared/constants/report-status.constants';
import { ReportService } from '@aca-new/app/pages/reports/shared/services/report/report.service';
import { ITableBodyCell } from '@aca-new/app/shared/components/table/shared/interfaces/table-data.interface';
import { ITableRowReferenceResult } from '@aca-new/app/shared/models/interfaces/report.interface';
import { QimaIconService } from '@aca-new/app/shared/services/exported-services/qima-icon/qima-icon.service';
import { QimaTooltipService } from '@aca-new/app/shared/services/exported-services/qima-tooltip/qima-tooltip.service';
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { has } from 'lodash/index';

@UntilDestroy()
@Component({
  selector: 'app-decision-label-cell',
  templateUrl: './decision-label-cell.component.html',
  styleUrls: ['./decision-label-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
})
export class DecisionLabelCellComponent implements OnInit, OnChanges {
  /**
   * @description
   * the data of the cell
   * @type {ITableBodyCell}
   * @default undefined
   */
  @Input('decisionLabelCellCellData')
  public cellData: QimaOptionalType<ITableBodyCell> = undefined;

  /**
   * @description
   * the origin data of the cell
   * @type {QimaOptionalType<unknown>}
   * @default undefined
   */
  @Input('decisionLabelCellOriginData')
  public originData: QimaOptionalType<unknown> = undefined;

  public rowData: QimaOptionalType<ITableRowReferenceResult> = undefined;
  public approvalBy: string = '';
  public statusType = REPORTS_STATUS;
  public reasonState: string = '';

  public constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _translateService: TranslateService,
    private readonly _reportService: ReportService,
    private readonly _datePipe: DatePipe,
    public readonly qimaIcon: QimaIconService,
    public readonly qimaTooltip: QimaTooltipService
  ) {}

  public ngOnInit(): void {
    this.initData();
    this._watch();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (has(changes, 'originData')) {
      this.initData();
    }
  }

  public initData(): void {
    this.reasonState = '';
    this.rowData = this.originData as ITableRowReferenceResult;

    if (this.cellData) {
      this.approvalBy = this._translateService.instant('REPORTS.VIEW_REPORT.APPROVAL_BY', {
        time: this._datePipe.transform(this.rowData.decisionTime || '', 'yyyy-MM-dd'),
        name: this.rowData.decidedBy,
      });
      const approvalReason: string = this._reportService.getReasonState(
        ((this.cellData.label === REPORTS_STATUS.approved ? this.rowData.approvalReason || '' : this.rowData.rejectionReason || '') || this.rowData.decisionReasons) ?? ''
      );
      const title: string =
        this.cellData.label === REPORTS_STATUS.approved
          ? this._translateService.instant('REPORTS.VIEW_REPORT.APPROVAL_REASON')
          : this._translateService.instant('REPORTS.VIEW_REPORT.REJECTION_REASON');
      const comment: string = this.rowData.comments ? `<br><br>${this._translateService.instant('REPORTS.VIEW_REPORT.COMMENTS_TO_SUPPLIER')}<br>${this.rowData.comments}` : '';

      this.reasonState = this.approvalBy + (approvalReason ? `<br><br>${title}<br>${approvalReason}` : '') + comment;
    }

    this._changeDetectorRef.detectChanges();
  }

  private _watch(): void {
    this._watchLanguage();
  }

  private _watchLanguage(): void {
    this._translateService.onLangChange.pipe(untilDestroyed(this)).subscribe((): void => {
      this.initData();
    });
  }
}
