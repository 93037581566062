import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { UserService } from '@aca-new/app/pages/user/shared/service/user.service';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QimaOptionalType } from '@qima/ngx-qima';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

declare let SHOULD_INIT_CHAT_BOX: boolean;
declare let FRESH_CHAT_TOKEN: string;

interface IFcWidgetUser {
  data: {
    restoreId: string;
  };
  status: number;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class FreshChatService {
  public shouldInitChatBox: boolean = this._window.shouldInitChatBox ?? SHOULD_INIT_CHAT_BOX;
  public freshChatToken: string = this._window.freshChatToken ?? FRESH_CHAT_TOKEN;
  public constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(WINDOW) private readonly _window: IMyWindow,
    private readonly _httpService: HttpService,
    private readonly _userService: UserService
  ) {}

  public initChat(userData: IUser): void {
    if (!this.shouldInitChatBox) {
      return;
    }

    let tag = 'ACACOM';

    if (this._window.location.host.includes('my.qima.cn')) {
      tag = 'ACACN';
    }

    if (!this._widgetLoaded(tag)) {
      this._initFreshChat(tag, userData);
    }

    this._showWidget(true);
  }

  public clearFreshChat(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowRef = this._document.defaultView as any;

    windowRef.fcWidget.user.clear();
    this._showWidget(false);
  }

  private _showWidget(isShow: boolean): void {
    const element = this._document.getElementsByClassName('custom_fc_frame')[0] as HTMLElement;

    if (!element) {
      return;
    }

    element.style.display = isShow ? 'block' : 'none';
  }

  private _widgetLoaded(tag: string): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowRef = this._document.defaultView as any;

    windowRef.fcWidget.setTags([tag]);

    return windowRef.fcWidget.isLoaded() == true;
  }

  private _initFreshChat(tag: string, userData: IUser): void {
    const [email] = [userData.email.split(';')[0]];
    const { login, firstName, lastName } = userData;
    const freshChatRestoreId = userData.restoreId;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowRef = this._document.defaultView as any;
    const freshChatConfig: Record<string, unknown> = {
      token: this.freshChatToken,
      host: 'https://qima-operations.freshchat.com',
      tags: [tag], // This is used to set the chat channel to assign users to
      externalId: userData.id,
      restoreId: freshChatRestoreId || null,
    };

    if (tag === 'ACACN') {
      freshChatConfig.widgetUuid = '4730acd3-b198-40d3-bbef-61ed141992ab';
    }

    windowRef.fcWidget.init(freshChatConfig);
    windowRef.fcWidget.user.setProperties({
      email,
      firstName,
      lastName,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Login: login,
      plan: 'Pro',
      status: 'Active',
    });
    windowRef.fcWidget.user.get((resp: QimaOptionalType<IFcWidgetUser>): void => {
      const status = resp?.status;

      // set properties if user not created yet
      // or no restoreId saved
      if (status !== 200) {
        windowRef.fcWidget.on('user:created', (resp: QimaOptionalType<IFcWidgetUser>): void => {
          const status = resp?.status;
          const data = resp?.data;

          if (status === 200 && data?.restoreId) {
            // store restoreId in your database if it doesn't exist yet
            this._saveRestoreId$(data.restoreId, userData.id).pipe(untilDestroyed(this)).subscribe();
          }
        });
      }
    });
  }

  private _saveRestoreId$(restoreId: string, userId: string): Observable<void> {
    return this._httpService.httpClient.put<void>(`${SERVER_URL}/user/v2/${userId}/${restoreId}`, {}, { observe: 'body' }).pipe(
      map((): void => {
        this._userService.watchUserDataRefresh$().pipe(untilDestroyed(this)).subscribe();
      })
    );
  }
}
