import { ETableDataType } from '@aca-new/app/shared/components/table/shared/enums/table-data-type.enum';
import { ETableHeaderLayoutMode } from '@aca-new/app/shared/components/table/shared/enums/table-header-layout-mode.enum';
import { ITableBodyCell } from '@aca-new/app/shared/components/table/shared/interfaces/table-data.interface';
import { ITableHeader } from '@aca-new/app/shared/components/table/shared/interfaces/table-header.interface';
import { EQimaTableHeadCellSort } from '@qima/ngx-qima';

const CHECKBOX: ITableHeader = {
  key: 'checkbox',
  label: '',
  type: ETableDataType.CHECKBOX,
  mode: ETableHeaderLayoutMode.STICKY_LEFT,
  width: '36',
};
const SERVICE_TYPE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'serviceType',
  iceCubeKey: 'serviceTypeText',
  label: 'TABLE.TYPE',
  type: ETableDataType.ICE_CUBE,
  width: '70',
};
const SERVICE_DATE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'inspectionDateMMMFormat',
  sortKey: 'inspectionDate',
  label: 'TABLE.SERVICE_DATE',
  type: ETableDataType.STRING,
  width: '124',
};
const RE_INSPECTION: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'isReInspection',
  label: 'TABLE.RE_INSPECTION',
  type: ETableDataType.STRING,
  width: '124',
};
const PRODUCT_NAME: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'productName',
  label: 'TABLE.PRODUCT_NAME',
  type: ETableDataType.STRING,
  width: '156',
};
const PRODUCT_NAME_MULTIPLE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'productName',
  label: 'TABLE.PRODUCT_NAME',
  type: ETableDataType.MULTIPLE_LABEL_CELL,
  width: '170',
  cellTransform(cellData: ITableBodyCell): void {
    cellData.value = 'TABLE.PRODUCTS';
  },
};
const PRODUCT_REF: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'prodReference',
  label: 'TABLE.PRODUCT_REF',
  type: ETableDataType.MULTIPLE_LABEL_CELL,
  width: '168',
  cellTransform(cellData: ITableBodyCell): void {
    cellData.label = cellData.label?.replace(/,/g, '###');
    cellData.value = 'TABLE.SKU';
  },
};
const PO_NO: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'poNumber',
  label: 'TABLE.PO_NO',
  type: ETableDataType.MULTIPLE_LABEL_CELL,
  width: '132',
  cellTransform(cellData: ITableBodyCell): void {
    cellData.label = cellData.label?.replace(/\//g, '###');
    cellData.value = 'TABLE.PO_NOS';
  },
};
const BOOKING_REF_NO: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'clientRefNb',
  label: 'TABLE.BOOKING_REF_NO',
  type: ETableDataType.STRING,
  width: '150',
};
const CLIENT_REF_NO: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'clientRefNumber',
  label: 'TABLE.CLIENT_REF_NUMBER',
  type: ETableDataType.STRING,
  width: '150',
};
const REF_NO: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'orderNumber',
  label: 'TABLE.REF_NO',
  type: ETableDataType.STRING,
  width: '160',
};
const SUPPLIER: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'supplierName',
  label: 'TABLE.SUPPLIER',
  type: ETableDataType.STRING,
  width: '130',
};
const RESULT: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'overrallResult',
  label: 'TABLE.RESULT',
  type: ETableDataType.BADGE,
  mode: ETableHeaderLayoutMode.STICKY_RIGHT,
  width: '94',
};
const YOUR_DECISION: ITableHeader = {
  key: 'status',
  label: 'TABLE.YOUR_DECISION',
  type: ETableDataType.DOT_LABEL,
  mode: ETableHeaderLayoutMode.STICKY_RIGHT,
  tooltip: 'REPORTS.YOUR_DECISION_TOOLTIP',
  width: '100',
};
const COUNTRY: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'countryName',
  label: 'Country',
  type: ETableDataType.STRING,
  width: '100',
};
const AUDIT_DATE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'auditDate',
  sortKey: 'auditDate',
  label: 'TABLE.AUDIT_DATE',
  type: ETableDataType.STRING,
};
const AUDIT_SCHEME: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'auditScheme',
  label: 'TABLE.AUDIT_SCHEME',
  type: ETableDataType.STRING,
};
const CERT_TYPE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'certificateType',
  label: 'TABLE.CERT_TYPE',
  type: ETableDataType.STRING,
};
const PRODUCER_ENTITY: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'producerLegalEntity',
  label: 'TABLE.PRODUCER_ENTITY',
  type: ETableDataType.STRING,
};
const WQS_ID: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'wqsId',
  label: 'TABLE.WQS_ID',
  type: ETableDataType.STRING,
};
const ORDER_NO: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'orderNumber',
  label: 'TABLE.ORDER_NO',
  type: ETableDataType.STRING,
};
const WQS_RESULT: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'result',
  label: 'TABLE.RESULT',
  type: ETableDataType.STRING,
};
const CERT_EXP_DATE: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'certificateExpirationDate',
  label: 'TABLE.CERT_EXP_DATE',
  type: ETableDataType.STRING,
};
const AUDIT_WINDOW_DATES: ITableHeader = {
  sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
  key: 'auditWindow',
  label: 'TABLE.AUDIT_WINDOW_DATES',
  type: ETableDataType.MULTIPLE_LABEL_CELL,

  cellTransform(cellData: ITableBodyCell, pageItem): void {
    const fromDate = pageItem?.auditWindowFromDate;
    const toDate = pageItem?.auditWindowToDate;

    if (fromDate && toDate) {
      cellData.label = `${fromDate} to ${toDate}`;
    }
  },
};

export {
  CHECKBOX,
  SERVICE_TYPE,
  SERVICE_DATE,
  REF_NO,
  YOUR_DECISION,
  RESULT,
  SUPPLIER,
  BOOKING_REF_NO,
  PO_NO,
  PRODUCT_REF,
  PRODUCT_NAME,
  RE_INSPECTION,
  COUNTRY,
  PRODUCT_NAME_MULTIPLE,
  CLIENT_REF_NO,
  AUDIT_DATE,
  AUDIT_SCHEME,
  CERT_TYPE,
  PRODUCER_ENTITY,
  WQS_ID,
  ORDER_NO,
  WQS_RESULT,
  CERT_EXP_DATE,
  AUDIT_WINDOW_DATES,
};
