import { ReportService } from '@aca-new/app/pages/reports/shared/services/report/report.service';
import { ESortOrder } from '@aca-new/app/shared/components/data-table/shared/models/enums/sort-order.enum';
import { DataTableResponseType, IDataTableData, PageItemType } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-data.interface';
import { IDataTableProgressIndicator } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-progress-indicator.interface';
import { IDataTableTransformCell } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-transform-cell.interface';
import { DataTableHttpService } from '@aca-new/app/shared/components/data-table/shared/service/data-table.http.service';
import { DataTableService } from '@aca-new/app/shared/components/data-table/shared/service/data-table.service';
import {
  ICustomizedTableCellConfiguration,
  ICustomizedTableCellData,
} from '@aca-new/app/shared/components/table/shared/components/customized-table-cell/shared/interfaces/customized-table-cell.interface';
import { ETableDataType } from '@aca-new/app/shared/components/table/shared/enums/table-data-type.enum';
import { ETableLayoutStyle } from '@aca-new/app/shared/components/table/shared/enums/table-style.enum';
import { ITableCheckbox } from '@aca-new/app/shared/components/table/shared/interfaces/table-checkbox.interface';
import { ITableBodyCell, ITableBodyRow } from '@aca-new/app/shared/components/table/shared/interfaces/table-data.interface';
import { ITableHeader } from '@aca-new/app/shared/components/table/shared/interfaces/table-header.interface';
import { ITableSort } from '@aca-new/app/shared/components/table/shared/interfaces/table-sort.interface';
import { EServiceType } from '@aca-new/app/shared/models/enums/service-type.enum';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { ProgressIndicatorService } from '@aca-new/app/shared/services/table-services/progress-indicator/progress-indicator.service';
import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { EQimaCheckboxState, QimaOptionalType } from '@qima/ngx-qima';
import { get, has } from 'lodash/index';
import { v4 as uuidv4 } from 'uuid';

@UntilDestroy()
@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DataTableService, ProgressIndicatorService, ReportService],
})
export class DataTableComponent implements OnInit, OnChanges {
  /**
   * @description
   * The style of table can click or not
   * @type {boolean}
   * @default {true}
   */
  @Input('dataTableCanClick')
  public canClick: boolean = true;

  /**
   * @description
   * Set the fallback message of the empty table
   * @type {string}
   * @default ''
   */
  @Input('dataTableEmptyMessage')
  public emptyMessage: string = '';

  /**
   * @description
   * Show or hide the app table super master select
   * @type {boolean}
   * @default false
   */
  @Input('dataTableIsSuperMasterSelectorVisible')
  public isSuperMasterSelectorVisible: boolean = false;

  /**
   * @description
   * Show or hide the table pagination
   * @type {boolean}
   * @default false
   */
  @Input('dataTableIsPaginationVisible')
  public isPaginationVisible: boolean = false;

  /**
   * @description
   * The table bottom is position absolute or not
   * @type {boolean}
   * @default true
   */
  @Input('dataTableIsBottomAbsolute')
  public isBottomAbsolute: boolean = true;

  /**
   * @description
   * Show or hide the toolbar top
   * @type {boolean}
   * @default true
   */
  @Input('dataTableIsToolbarTopVisible')
  public isToolbarTopVisible: boolean = true;

  /**
   * @description
   * Show or hide the toolbar bottom
   * @type {boolean}
   * @default true
   */
  @Input('dataTableIsToolbarBottomVisible')
  public isToolbarBottomVisible: boolean = true;

  /**
   * @description
   * Pagination in front
   * @type {boolean}
   * @default false
   */
  @Input('dataTableIsFrontPagination')
  public isFrontPagination: boolean = false;

  /**
   * @description
   * Set the data table selectable
   * @type {boolean}
   * @default false
   */
  @Input('dataTableIsSelectable')
  public isSelectable: boolean = false;

  /**
   * @description
   * The list of entries count per page
   * @type {number[]}
   * @default []
   */
  @Input('dataTablePageSizes')
  public pageSizes: number[] = [];

  /**
   * @description
   * Search value for front
   * @type {string}
   * @default ''
   */
  @Input('dataTableFrontSearchValue')
  public frontSearchValue: string = '';

  /**
   * @description
   * Api for request data
   * @type {string}
   * @default ''
   */
  @Input('dataTableUrl')
  public apiUrl: QimaOptionalType<string> = '';

  /**
   * @description
   * Data table show global loading or not when get data from backend
   * @type {boolean}
   * @default true
   */
  @Input('dataTableHasGlobalLoading')
  public hasGlobalLoading: boolean = true;

  /**
   * @description
   * Table header config data
   * @type {ITableHeader[]}
   * @default []
   */
  @Input('dataTableHeaders')
  public tableHeaders: ITableHeader[] = [];

  /**
   * @description
   * Is table head visible
   * @type {boolean}
   * @default true
   */
  @Input('dataTableIsHeadVisible')
  public isHeadVisible: boolean = true;

  /**
   * @description
   * The style of data table layout
   * @type {QimaOptionalType<ETableLayoutStyle>}
   * @default {@link ETableLayoutStyle.ABSOLUTE_WITH_TOP_BOTTOM}
   */
  @Input('dataTableStyle')
  public layoutStyle: QimaOptionalType<ETableLayoutStyle> = ETableLayoutStyle.ABSOLUTE_WITH_TOP_BOTTOM;

  /**
   * @description
   * The style top of data table
   * @type {QimaOptionalType<number>}
   * @default 56
   */
  @Input('dataTableTop')
  public top: QimaOptionalType<number> = 56;

  /**
   * @description
   * The style bottom of data table
   * @type {QimaOptionalType<number>}
   * @default 55
   */
  @Input('dataTableBottom')
  public bottom: QimaOptionalType<number> = 40;

  /**
   * @description
   * Methods for customizing data transformation: source data to table data
   * @type {QimaOptionalType<(source: Readonly<IDataTableData>, headers: ITableHeader[]) => ITableBodyRow[]>}
   * @default undefined
   */
  @Input('dataTableSourceDataConvertTableViewData')
  public sourceDataConvertTableViewData: QimaOptionalType<(source: Readonly<IDataTableData>, headers: ITableHeader[]) => ITableBodyRow[]> = undefined;

  /**
   * @description
   * Hook function before row click
   * @type {QimaOptionalType<() => boolean>}
   * @default undefined
   */
  @Input('dataTableBeforeRowClick')
  public beforeRowClick: QimaOptionalType<() => boolean> = undefined;

  /**
   * @description
   * Hook function before checkbox click
   * @type {QimaOptionalType<(rows: ITableBodyRow[]) => boolean>}
   * @default undefined
   */
  @Input('dataTableBeforeCheckboxClick')
  public beforeCheckboxClick: QimaOptionalType<(rows: ITableBodyRow[]) => boolean> = undefined;

  /**
   * @description
   * Methods for source data transformation
   * @type {string}
   * @default ''
   */
  @Input('dataTableTransformSourceData')
  public transformSourceData: QimaOptionalType<(source: unknown) => DataTableResponseType> = undefined;

  /**
   * @description
   * Methods for transform some(specified quantity) table cells
   * @type {QimaOptionalType<Record<string, (data: IDataTableTransformCell) => void>>}
   * @default undefined
   */
  @Input('dataTableTransformTableCellMap')
  public transformTableCellMap: QimaOptionalType<Record<string, (data: IDataTableTransformCell) => void>> = undefined;

  /**
   * @description
   * Methods for transform all table cells
   * @type {QimaOptionalType<(cellData: ITableBodyCell, pageItem: Record<string, PageItemType>, head: ITableHeader) => void>}
   * @default undefined
   */
  @Input('dataTableTransformAllTableCells')
  public transformAllTableCells: QimaOptionalType<(cellData: ITableBodyCell, pageItem: Record<string, PageItemType>, head: ITableHeader) => void> = undefined;

  /**
   * @description
   * Data table display striped style
   * @type {boolean}
   * @default false
   */
  @Input('dataTableIsStriped')
  public isStriped: boolean = false;

  /**
   * @description
   * Data table display hover style
   * @type {boolean}
   * @default true
   */
  @Input('dataTableIsHover')
  public isHover: boolean = true;

  /**
   * @description
   * Table header is fixed
   * @type {boolean}
   * @default true
   */
  @Input('dataTableIsHeaderFix')
  public isHeaderFix: boolean = true;

  /**
   * @description
   * Table row has select color
   * @type {boolean}
   * @default false
   */
  @Input('dataTableHasSelectedBackgroundColor')
  public hasSelectedBackgroundColor: boolean = false;

  /**
   * @description
   * Display app-table only
   * @type {boolean}
   * @default false
   */
  @Input('dataTableIsSimpleTable')
  public isSimpleTable: boolean = false;

  /**
   * @description
   * Table source data
   * @type {ITableBodyRow[]}
   * @default []
   */
  @Input('dataTableSourceData')
  public sourceData: ITableBodyRow[] = [];

  /**
   * @description
   * Table row default active row index
   * @type {number}
   * @default -1
   */
  @Input('dataTableActiveRowIndex')
  public activeRowIndex: number = -1;

  @Input('dataTableActivePage')
  public activePage: number = 1;

  @Output('dataTablePaginationChange')
  public readonly paginationChange: EventEmitter<Readonly<number>> = new EventEmitter<Readonly<number>>();

  @Output('dataTablePageSizeChange')
  public readonly pageSizeChange: EventEmitter<Readonly<number>> = new EventEmitter<Readonly<number>>();

  @Output('dataTableRowClick')
  public readonly rowClick: EventEmitter<Readonly<ITableBodyRow>> = new EventEmitter<Readonly<ITableBodyRow>>();

  @Output('dataTableCheckboxClick')
  public readonly checkboxClick: EventEmitter<Readonly<ITableBodyRow[]>> = new EventEmitter<Readonly<ITableBodyRow[]>>();

  @Output('dataTableIconCellClick')
  public readonly iconCellClick: EventEmitter<number> = new EventEmitter<number>();

  @Output('dataTableTableBackendSort')
  public readonly backendSort: EventEmitter<ITableSort> = new EventEmitter<ITableSort>();

  @Output('dataTableSuperMasterSelectorChange')
  public readonly superMasterSelectorChange: EventEmitter<string> = new EventEmitter<string>();

  @Output('dataTableIsLoading')
  public readonly isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  public tableViewData: ITableBodyRow[] = [];
  public tableOriginalData: QimaOptionalType<Record<string, PageItemType>[]> = undefined;
  public tableTotalSize: number = 0;
  public apiUrlCache: string = '';
  public selectedRows: ITableBodyRow[] = [];
  public emptyMessageState: string = '';
  public isSuperMaster: boolean = this._appUserSettingService.getIsSuperMaster();

  private _sourceData: unknown = undefined;

  public constructor(
    private readonly _appUserSettingService: AppUserSettingService,
    private readonly _dataTableService: DataTableService,
    private readonly _dataTableHttpService: DataTableHttpService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _overlayService: AppOverlayService,
    private readonly _progressIndicatorService: ProgressIndicatorService,
    private readonly _reportService: ReportService,
    private readonly _translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.initTable();
    this.watch();
    this.initFront();
  }

  public ngOnChanges(changes: Readonly<SimpleChanges>): void {
    this._onHeaderChange(changes);
    this._onUrlChange(changes);
    this._onSearchValueChange(changes);
    this._onSourceDataChange(changes);
    this._onActivePageChange(changes);
  }

  public initTable(): void {
    if (this.isSelectable && this.tableHeaders[0].type !== ETableDataType.CHECKBOX) {
      this.tableHeaders.unshift({
        key: 'checkbox',
        label: '',
        type: ETableDataType.CHECKBOX,
      });
    }
  }

  public watch(): void {
    const { dataTableSubject$ } = this._dataTableService;

    dataTableSubject$.pipe(untilDestroyed(this)).subscribe((res): void => {
      this.tableViewData = res;

      if (this.isFrontPagination) {
        this.tableTotalSize = this._dataTableService.tableTotalSize;
      }

      if (!this.tableTotalSize) {
        this.emptyMessageState = this.emptyMessage;
      }

      this._changeDetectorRef.markForCheck();
    });

    this._dataTableService.isAllChecked$.pipe(untilDestroyed(this)).subscribe((isAllChecked: boolean): void => {
      this.selectedRows = [];

      const data: ITableBodyRow[] = this.sourceData || this.tableViewData;

      if (isAllChecked) {
        data.forEach((row: ITableBodyRow): void => {
          this.selectedRows.push(row);
        });
      }

      // TODO we should only emit the origin data array
      this.checkboxClick.emit(this.selectedRows);
      this._changeDetectorRef.markForCheck();
    });

    this._dataTableHttpService.refreshTable$.pipe(untilDestroyed(this)).subscribe((url: string): void => {
      this.getTableData(url);
    });
  }

  public initFront(): void {
    if (this.isFrontPagination) {
      this._dataTableService.updatePagination(1, this.pageSizes[0]);
    }
  }

  public onSuperMasterSelectorClick(id: string): void {
    this.superMasterSelectorChange.emit(id);
  }

  public resetTable(): void {
    this.selectedRows = [];
    this._dataTableService.updateIsAllChecked(false);
    this._changeDetectorRef.markForCheck();
  }

  public clearSelectedRows(): void {
    this._dataTableService.updateIsAllChecked(false);
  }

  // TODO
  public onTableFilter(): void {
    this._dataTableService.updateFilter('filter test');
  }

  public onTableSort(sortEvent: ITableSort): void {
    const sortOrder = sortEvent.isSortAsc ? ESortOrder.ASC : ESortOrder.DESC;
    const header: ITableHeader = this.tableHeaders[sortEvent.position];

    if (header?.isFrontSort) {
      const { sortFunction } = header;

      this._dataTableService.updateSort({ index: sortEvent.position, order: sortOrder, sortFunction });

      return;
    }

    // backend sort
    this._dataTableService.updateSort(undefined);
    this.backendSort.emit(sortEvent);
  }

  public onTableRowClick(tableRow: Readonly<ITableBodyRow>): void {
    this.addSelectRows(tableRow);
    this.rowClick.emit(tableRow);
  }

  public onTableCheckboxClick(tableRow: Readonly<ITableBodyRow>): void {
    this.addSelectRows(tableRow);
  }

  public addSelectRows(tableRow: Readonly<ITableBodyRow>): void {
    const rowIndex = this.selectedRows.findIndex((row: ITableBodyRow): boolean => row.rowId === tableRow.rowId);

    if (rowIndex >= 0) {
      this.selectedRows.splice(rowIndex, 1);
    } else {
      this.selectedRows.push(tableRow);
    }

    this.checkboxClick.emit(this.selectedRows);
  }

  public onTableHeadCheckboxClick(data: Readonly<ITableCheckbox>): void {
    this._dataTableService.updateIsAllChecked(data.state === EQimaCheckboxState.CHECKED);

    if (data.state === EQimaCheckboxState.CHECKED) {
      this.selectedRows = [...this.tableViewData];
    } else {
      this.selectedRows = [];
    }

    this.checkboxClick.emit(this.selectedRows);
  }

  // TODO rename
  public onTableIconClick(index: number): void {
    this.iconCellClick.emit(index);
  }

  public tablePaginationActivePageChange(page: Readonly<number>): void {
    if (page === this.activePage) {
      return;
    }

    this.activePage = page;

    if (this.isFrontPagination) {
      this._dataTableService.updatePageIndex(page);
    }

    this.paginationChange.emit(page);
  }

  public tablePaginationPageSizeChange(pageSize: Readonly<number>): void {
    if (this.isFrontPagination) {
      this._dataTableService.updatePageSize(pageSize);
    }

    this.pageSizeChange.emit(pageSize);
  }

  public transformAllTableCellsDefault(cellData: ITableBodyCell, pageItem: Record<string, PageItemType>, head: ITableHeader): void {
    if (head.prefix) {
      cellData.prefix = head.prefix;
    }

    this._setCellByType(cellData, pageItem, head);

    if (head.cellTransform) {
      head.cellTransform(cellData, pageItem);
    }
  }

  public getTableData(apiUrl: string = ''): void {
    if (!this.apiUrl) {
      return;
    }

    this.isLoading.emit(true);
    this._overlayService.updateIsLoading(this.hasGlobalLoading);
    this.emptyMessageState = '';
    this._dataTableHttpService
      .getTableData$(apiUrl || this.apiUrl)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (res: unknown): void => {
          this._overlayService.updateIsLoading(false);
          this.isLoading.emit(false);

          if (!res) {
            this.tableTotalSize = 0;
            this._handleIDataTableDataResponse({
              pageItems: [],
              totalSize: 0,
              pageNo: '1',
            });
          }

          if (this.transformSourceData) {
            res = this.transformSourceData(res);
          }

          this._sourceData = res;
          this._formatTableDate();
        },
        error: (): void => {
          this.tableTotalSize = 0;
          this._handleIDataTableDataResponse({
            pageItems: [],
            totalSize: 0,
            pageNo: '1',
          });
          this.isLoading.emit(false);
        },
      });
  }

  private _formatTableDate(): void {
    if (!this._sourceData) {
      return;
    }

    if ((this._sourceData as Record<string, PageItemType>[]).length) {
      const pageItems: Record<string, PageItemType>[] = this._sourceData as Record<string, PageItemType>[];

      this.tableTotalSize = pageItems.length;
      this._handleIDataTableDataResponse({
        pageItems,
      });
    } else if (((this._sourceData as Record<string, unknown>).content as Record<string, PageItemType>[])?.length) {
      // factories
      const pageItems: Record<string, PageItemType>[] = (this._sourceData as Record<string, unknown>).content as Record<string, PageItemType>[];

      this.tableTotalSize = pageItems.length;
      this._handleIDataTableDataResponse({
        pageItems,
      });
    } else {
      let dataTableResponse: IDataTableData = this._sourceData as IDataTableData;

      // default transform
      if (dataTableResponse.content) {
        dataTableResponse = dataTableResponse.content;
      }

      this.tableTotalSize = dataTableResponse.totalSize || 0;
      this._handleIDataTableDataResponse(dataTableResponse);
    }

    if (this.tableTotalSize === 0) {
      this.emptyMessageState = this.emptyMessage;
    }
  }

  private _handleIDataTableDataResponse(res: IDataTableData): void {
    let tableViewData: ITableBodyRow[] = [];

    this.tableOriginalData = res.pageItems;

    // 1 convert to T[][]
    if (this.sourceDataConvertTableViewData) {
      tableViewData = this.sourceDataConvertTableViewData(res, this.tableHeaders);
    } else {
      tableViewData = this._sourceDataConvertTableViewData(res, this.tableHeaders);
    }

    // 2 update
    this._dataTableService.updateTableData(tableViewData);
  }

  private _setCellByType(cellData: ITableBodyCell, pageItem: Record<string, PageItemType>, head: ITableHeader): void {
    if (!head.key) {
      return;
    }

    let cellDataTmp: QimaOptionalType<IDataTableProgressIndicator>;

    switch (cellData.type) {
      case ETableDataType.STRING:
      case ETableDataType.NUMBER:
      case ETableDataType.LINK:
      case ETableDataType.DATE:
        cellData.label = (get(pageItem, head.key) as string) || '';

        if (head.suffixKey && pageItem[head.suffixKey]) {
          cellData.label += ` ${(pageItem[head.suffixKey] as string) || ''}`;
        }

        if (head.prefixKey && pageItem[head.prefixKey]) {
          cellData.label = `${(pageItem[head.prefixKey] as string) || ''} ${cellData.label}`;
        }

        break;
      case ETableDataType.ICE_CUBE:
        if (head.iceCubeKey) {
          let label = (get(pageItem, head.iceCubeKey) || '') as string;

          if (label.toLowerCase().startsWith(EServiceType.SASO.toLowerCase())) {
            label = EServiceType.SASO;
          }

          cellData.label = label;
        }

        break;
      case ETableDataType.PROGRESS_INDICATOR:
        this._progressIndicatorService.setProgressIndicator(pageItem);
        cellDataTmp = this._progressIndicatorService.getProgressIndicator();

        if (cellDataTmp) {
          cellData.tooltip = cellDataTmp.tooltip;
          cellData.progressIndicatorValue = cellDataTmp.activeStep;
        }

        break;
      case ETableDataType.CHECKBOX:
        cellData.checkState = EQimaCheckboxState.UNCHECKED;
        break;
      case ETableDataType.BADGE:
        cellData.label = (get(pageItem, head.key) as string) || '';
        cellData.suffix = pageItem.missedInspection ? this._translateService.instant('REPORTS.VIEW_REPORT.MISSED') : '';
        break;
      case ETableDataType.DOT_LABEL:
        cellData.label = this._reportService.getState((get(pageItem, head.key) as string) || '');
        break;
      case ETableDataType.CUSTOMIZED:
        cellData.customizedCellConfiguration = get(pageItem, head.key) as ICustomizedTableCellConfiguration<ICustomizedTableCellData>;
        break;
      case ETableDataType.MULTIPLE_LABEL_CELL:
        cellData.label = get(pageItem, head.key) as string;
        break;
    }
  }

  private _onUrlChange(changes: Readonly<SimpleChanges>): void {
    if (has(changes, 'apiUrl') && changes.apiUrl.currentValue !== changes.apiUrl.previousValue) {
      this.apiUrlCache = this.apiUrl || '';
      this.getTableData();
    }
  }

  private _onSearchValueChange(changes: Readonly<SimpleChanges>): void {
    if (has(changes, 'frontSearchValue') && changes.frontSearchValue.currentValue !== changes.frontSearchValue.previousValue) {
      this._dataTableService.updateSearchValue(this.frontSearchValue);
    }
  }

  private _onActivePageChange(changes: Readonly<SimpleChanges>): void {
    if (has(changes, 'activePage') && changes.activePage.currentValue !== changes.activePage.previousValue) {
      this._dataTableService.updatePageIndex(this.activePage);
    }
  }

  private _onHeaderChange(changes: Readonly<SimpleChanges>): void {
    if (has(changes, 'tableHeaders') && changes.tableHeaders.currentValue !== changes.tableHeaders.previousValue) {
      this.tableViewData = [];
      this._formatTableDate();
    }
  }

  private _onSourceDataChange(changes: Readonly<SimpleChanges>): void {
    if (has(changes, 'sourceData') && changes.sourceData.currentValue !== changes.sourceData.previousValue) {
      this._dataTableService.updateTableData(this.sourceData);
    }
  }

  private _sourceDataConvertTableViewData(source: Readonly<IDataTableData>, headers: ITableHeader[]): ITableBodyRow[] {
    const tableData: ITableBodyRow[] = [];

    if (source.pageItems?.length) {
      source.pageItems.forEach((pageItem: Record<string, PageItemType>): void => {
        const rowData: ITableBodyCell[] = [];

        headers?.forEach((head: ITableHeader): void => {
          const cellData: ITableBodyCell = {
            type: ETableDataType.STRING,
          };

          cellData.type = head.type;
          cellData.cellClick = (event: Event): void => {
            if (head.cellClick) {
              event.preventDefault();
              event.stopPropagation();
              head.cellClick(pageItem);
            }
          };

          if (this.transformAllTableCells) {
            this.transformAllTableCells(cellData, pageItem, head);
          } else {
            this.transformAllTableCellsDefault(cellData, pageItem, head);
          }

          const { transformTableCellMap } = this;

          if (transformTableCellMap?.[cellData.type]) {
            transformTableCellMap?.[cellData.type]({ cellData, pageItem, head });
          }

          rowData.push(cellData);
        });

        tableData.push({
          rowId: uuidv4(),
          viewData: rowData,
          originData: pageItem,
        });
      });
    }

    return tableData;
  }
}
