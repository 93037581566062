import { DataTableService } from '@aca-new/app/shared/components/data-table/shared/service/data-table.service';
import { ETableDataType } from '@aca-new/app/shared/components/table/shared/enums/table-data-type.enum';
import { ETableHeaderLayoutMode } from '@aca-new/app/shared/components/table/shared/enums/table-header-layout-mode.enum';
import { ETableLayoutStyle } from '@aca-new/app/shared/components/table/shared/enums/table-style.enum';
import { ITableCheckbox } from '@aca-new/app/shared/components/table/shared/interfaces/table-checkbox.interface';
import { ITableBodyCell, ITableBodyRow } from '@aca-new/app/shared/components/table/shared/interfaces/table-data.interface';
import { ITableHeader } from '@aca-new/app/shared/components/table/shared/interfaces/table-header.interface';
import { ITableSort } from '@aca-new/app/shared/components/table/shared/interfaces/table-sort.interface';
import { AppTableService } from '@aca-new/app/shared/services/exported-services/app-table/app-table.service';
import { AppTableDataCellTypeService } from '@aca-new/app/shared/services/exported-services/app-table-data-cell-type/app-table-data-cell-type.service';
import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { QimaTooltipService } from '@aca-new/app/shared/services/exported-services/qima-tooltip/qima-tooltip.service';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EQimaCheckboxState, QimaOptionalType, QimaSimpleChangesType } from '@qima/ngx-qima';
import { cloneDeep, isEmpty } from 'lodash/index';

@UntilDestroy()
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit {
  /**
   * @description
   * The style of table layout
   * @type {QimaOptionalType<ETableLayoutStyle>}
   * @default {@link ETableLayoutStyle.ABSOLUTE_WITH_TOP_BOTTOM}
   */
  @Input('layoutStyle')
  public layoutStyle: QimaOptionalType<ETableLayoutStyle> = ETableLayoutStyle.ABSOLUTE_WITH_TOP_BOTTOM;

  /**
   * @description
   * The style of table can click or not
   * @type {boolean}
   * @default {true}
   */
  @Input('tableCanClick')
  public canClick: boolean = true;

  /**
   * @description
   * The style top of table
   * @type {QimaOptionalType<number>}
   * @default 40
   */
  @Input('tableTop')
  public top: QimaOptionalType<number> = 40;

  /**
   * @description
   * The style bottom of table
   * @type {QimaOptionalType<number>}
   * @default 55
   */
  @Input('tableBottom')
  public bottom: QimaOptionalType<number> = 40;

  /**
   * @description
   * Set the data of the table header
   * @type {ITableHeader[]}
   * @default []
   */
  @Input('tableHeaders')
  public tableHeaders: ITableHeader[] = [];

  /**
   * @description
   * Is table head visible
   * @type {boolean}
   * @default true
   */
  @Input('tableIsHeadVisible')
  public isHeadVisible: boolean = true;

  /**
   * @description
   * Set the fallback message of the empty table
   * @type {string}
   * @default ''
   */
  @Input('tableEmptyMessage')
  public emptyMessage: string = '';

  /**
   * @description
   * Set the data of the table
   * @type {ITableBodyRow[]}
   * @default []
   */
  @Input('tableData')
  public tableData: ITableBodyRow[] = [];

  /**
   * @description
   * Table display striped style
   * @type {boolean}
   * @default false
   */
  @Input('tableIsStriped')
  public isStriped: boolean = false;

  /**
   * @description
   * Table display hover style
   * @type {boolean}
   * @default true
   */
  @Input('tableIsHover')
  public isHover: boolean = true;

  /**
   * @description
   * Table header is fixed
   * @type {boolean}
   * @default true
   */
  @Input('tableIsHeaderFix')
  public isHeaderFix: boolean = true;

  /**
   * @description
   * Table row has select color
   * @type {boolean}
   * @default false
   */
  @Input('tableHasSelectedBackgroundColor')
  public hasSelectedBackgroundColor: boolean = false;

  /**
   * @description
   * Table row default active row index
   * @type {number}
   * @default -1
   */
  @Input('tableActiveRowIndex')
  public activeRowIndex: number = -1;

  /**
   * @description
   * Hook function before row click
   * @type {QimaOptionalType<() => boolean>}
   * @default undefined
   */
  @Input('tableBeforeRowClick')
  public beforeRowClick: QimaOptionalType<() => boolean> = undefined;

  /**
   * @description
   * Hook function before checkbox click
   * @type {QimaOptionalType<(rows: ITableBodyRow[]) => boolean>}
   * @default undefined
   */
  @Input('tableBeforeCheckboxClick')
  public beforeCheckboxClick: QimaOptionalType<(rows: ITableBodyRow[]) => boolean> = undefined;

  /**
   * @description
   * Output event when click table sort
   */
  @Output('tableSort')
  public readonly sort: EventEmitter<ITableSort> = new EventEmitter<ITableSort>();

  /**
   * @description
   * Output event when click table row
   */
  @Output('tableRowClick')
  public readonly rowClick: EventEmitter<ITableBodyRow> = new EventEmitter<ITableBodyRow>();

  /**
   * @description
   * Output event when click table checkbox
   */
  @Output('tableCheckboxClick')
  public readonly checkboxClick: EventEmitter<ITableBodyRow> = new EventEmitter<ITableBodyRow>();

  /**
   * @description
   * Output event when click table head checkbox
   */
  @Output('tableHeadCheckboxClick')
  public readonly headCheckboxClick: EventEmitter<ITableCheckbox> = new EventEmitter<ITableCheckbox>();

  @Output('dataTableIconCellClick')
  public readonly iconCellClick: EventEmitter<number> = new EventEmitter<number>();

  @ViewChildren('stickyLeftRef')
  public readonly stickyLeftRef: QimaOptionalType<QueryList<ElementRef>> = undefined;

  @ViewChildren('stickyRightRef')
  public readonly stickyRightRef: QimaOptionalType<QueryList<ElementRef>> = undefined;

  public headCellCheckboxState: EQimaCheckboxState = EQimaCheckboxState.UNCHECKED;
  public renderedTableHeaders: ITableHeader[] = [];
  public stickyLeftHeaders: ITableHeader[] = [];
  public stickyRightHeaders: ITableHeader[] = [];
  public stickyLeftHeadersWidth = 0;
  public stickyRightHeadersWidth = 0;
  public stickyLeftObserver!: IntersectionObserver;
  public stickyRightObserver!: IntersectionObserver;

  public constructor(
    private readonly _dataTableService: DataTableService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    public readonly appTable: AppTableService,
    public readonly appTableDataCellService: AppTableDataCellTypeService,
    public readonly qimaButton: QimaButtonService,
    public readonly qimaTooltip: QimaTooltipService
  ) {}

  public ngOnInit(): void {
    this._watch();
    this._initTableHeaders();
  }

  public ngOnChanges(changes: QimaSimpleChangesType<TableComponent>): void {
    if (!isEmpty(changes.tableHeaders) && changes.tableHeaders.currentValue !== changes.tableHeaders.previousValue) {
      this._initTableHeaders();
    }

    if (!isEmpty(changes.tableData) && changes.tableData.currentValue !== changes.tableData.previousValue) {
      this._initTableCells();
    }
  }

  public ngAfterViewInit(): void {
    if (this.stickyLeftHeaders.length > 0) {
      this.stickyLeftObserver = new IntersectionObserver(this._leftIntersectionHandler.bind(this), { threshold: [1.0] });

      this.stickyLeftRef?.forEach((elementRef): void => {
        this.stickyLeftObserver.observe(elementRef.nativeElement as Element);
      });
    }

    if (this.stickyRightHeaders.length > 0) {
      this.stickyRightObserver = new IntersectionObserver(this._rightIntersectionHandler.bind(this), { threshold: [1.0] });

      this.stickyRightRef?.forEach((elementRef): void => {
        this.stickyRightObserver.observe(elementRef.nativeElement as Element);
      });
    }
  }

  public onHeadCellCheckboxClick(index: number): void {
    const cloneTableData = cloneDeep(this.tableData);

    cloneTableData.forEach((item): void => {
      item.viewData[0].checkState = this.headCellCheckboxState === EQimaCheckboxState.CHECKED ? EQimaCheckboxState.UNCHECKED : EQimaCheckboxState.CHECKED;
    });

    if (this.beforeCheckboxClick && !this.beforeCheckboxClick(cloneTableData)) {
      return;
    }

    this.headCellCheckboxState = this.headCellCheckboxState === EQimaCheckboxState.CHECKED ? EQimaCheckboxState.UNCHECKED : EQimaCheckboxState.CHECKED;
    this.headCheckboxClick.emit({ index, state: this.headCellCheckboxState });
  }

  public onSortAscendingChange(containsSortEvent: Readonly<boolean>, sortKey: Readonly<string>): void {
    const index = this.tableHeaders.findIndex((header): boolean => header.key === sortKey || header.sortKey === sortKey);

    this.sort.emit({ isSortAsc: containsSortEvent, position: index, sortKey });
  }

  // avoid checkbox click trigger row click
  public onCellCheckboxClick(event: MouseEvent | Event): void {
    event.stopPropagation();
  }

  public onTableBodyCellCheckboxClick(event: MouseEvent | Event, cellData: ITableBodyCell, row: ITableBodyRow): void {
    event.stopPropagation();

    const cloneTableData = cloneDeep(this.tableData);
    const currentStepIndex = cloneTableData.findIndex((item): boolean => item.rowId === row.rowId);

    cloneTableData[currentStepIndex].viewData[0].checkState =
      cloneTableData[currentStepIndex].viewData[0].checkState === EQimaCheckboxState.UNCHECKED ? EQimaCheckboxState.CHECKED : EQimaCheckboxState.UNCHECKED;

    if (this.beforeCheckboxClick && !this.beforeCheckboxClick(cellData.checkState === EQimaCheckboxState.UNCHECKED ? cloneTableData : [])) {
      return;
    }

    this.toggleCheckboxState(cellData);
    this.checkboxClick.emit(row);
  }

  public onRowClick(index: number, row: ITableBodyRow): void {
    if (this.beforeRowClick && !this.beforeRowClick()) {
      return this.rowClick.emit(row);
    }

    this.activeRowIndex = index;
    this._rowClick(index, row);
  }

  public onRowEnter(index: number, row: ITableBodyRow): void {
    this._rowClick(index, row);
  }

  public onIconCellClick(index: number): void {
    this.iconCellClick.emit(index);
  }

  public toggleCheckboxState(cellData: ITableBodyCell): void {
    cellData.checkState = cellData.checkState === EQimaCheckboxState.UNCHECKED ? EQimaCheckboxState.CHECKED : EQimaCheckboxState.UNCHECKED;
    this._checkIsAllChecked();
  }

  private _setAllTableCellCheckState(state: EQimaCheckboxState): void {
    this.headCellCheckboxState = state;
    this.tableData.forEach((row: ITableBodyRow): void => {
      row.viewData.forEach((cell: ITableBodyCell): void => {
        if (cell.type === ETableDataType.CHECKBOX) {
          cell.checkState = state;
        }
      });
    });
  }

  private _checkIsAllChecked(): void {
    const uncheckedCount = this.tableData.filter((row: ITableBodyRow): boolean => {
      return row.viewData[0].checkState === EQimaCheckboxState.UNCHECKED;
    }).length;

    if (uncheckedCount === this.tableData.length) {
      this.headCellCheckboxState = EQimaCheckboxState.UNCHECKED;
    } else if (uncheckedCount === 0) {
      this.headCellCheckboxState = EQimaCheckboxState.CHECKED;
    } else {
      this.headCellCheckboxState = EQimaCheckboxState.PARTIALLY_CHECKED;
    }
  }

  private _rowClick(index: number, row: ITableBodyRow): void {
    const [rowCell0] = this.tableData[index].viewData;

    if (rowCell0.type === ETableDataType.CHECKBOX && rowCell0.checkState) {
      this.toggleCheckboxState(rowCell0);
    }

    this.rowClick.emit(row);
  }

  private _leftIntersectionHandler(entries: IntersectionObserverEntry[]): void {
    this.stickyLeftRef?.forEach((element): void => {
      element.nativeElement.classList.toggle('is-sticky', entries[0].intersectionRatio < 1);
    });
  }

  private _rightIntersectionHandler(entries: IntersectionObserverEntry[]): void {
    this.stickyRightRef?.forEach((element): void => {
      element.nativeElement.classList.toggle('is-sticky', entries[0].intersectionRatio < 1);
    });
  }

  private _initTableHeaders(): void {
    this.renderedTableHeaders = [];
    this.stickyLeftHeaders = [];
    this.stickyRightHeaders = [];
    this.stickyLeftHeadersWidth = 0;
    this.stickyRightHeadersWidth = 0;

    this.tableHeaders.forEach((header: ITableHeader): void => {
      switch (header.mode) {
        case ETableHeaderLayoutMode.STICKY_LEFT:
          this.stickyLeftHeaders.push(header);
          this.stickyLeftHeadersWidth += +(header.width ?? 80);

          break;
        case ETableHeaderLayoutMode.STICKY_RIGHT:
          this.stickyRightHeaders.push(header);

          this.stickyRightHeadersWidth += +(header.width ?? 80);

          break;
        default:
          this.renderedTableHeaders.push(header);
          break;
      }
    });

    this._changeDetectorRef.markForCheck();
  }

  private _initTableCells(): void {
    this.tableData.forEach((row): void => {
      row.stickyLeftData = [];
      row.stickyRightData = [];
      row.renderedData = [];
      row.viewData.forEach((cell, index): void => {
        switch (this.tableHeaders[index].mode) {
          case ETableHeaderLayoutMode.STICKY_LEFT:
            row.stickyLeftData?.push(cell);
            break;
          case ETableHeaderLayoutMode.STICKY_RIGHT:
            row.stickyRightData?.push(cell);
            break;
          default:
            row.renderedData?.push(cell);
            break;
        }
      });
    });
    this._changeDetectorRef.markForCheck();
  }

  private _watch(): void {
    this._dataTableService.isAllChecked$.pipe(untilDestroyed(this)).subscribe((isAllChecked: boolean): void => {
      this._setAllTableCellCheckState(isAllChecked ? EQimaCheckboxState.CHECKED : EQimaCheckboxState.UNCHECKED);
      this._changeDetectorRef.markForCheck();
    });
  }
}
