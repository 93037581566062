<div class="service-type-select">
  <qima-input-select
    class="service-type-select__select"
    [ngModel]="selected"
    [qimaInputSelectStyle]="selectedStyle"
    [qimaInputSelectHasClearButton]="false"
  >
    <qima-menu [qimaMenuMaxHeight]="businessSolutionMenuMaxHeight">
      <qima-menu-item-text
        *ngFor="let item of businessSolutions"
        appNgForTrackByUuid
        (qimaMenuItemTextClick)="onBusinessSolutionChange(item.value)"
        [qimaMenuItemTextLabel]="item.label | translate"
        [qimaMenuItemTextValue]="item.value"
      ></qima-menu-item-text>
    </qima-menu>
  </qima-input-select>
</div>
