import { EBusinessUnit } from '@aca-new/app/shared/models/enums/business-unit.enum';
import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

/**
 * @description
 * Only analytics module is available for produce BU
 * @param {RouterStateSnapshot} state the state
 * @returns {boolean} - can access or not
 */
function produceGuard(state: RouterStateSnapshot): boolean {
  const producePaths = ['/analytics', '/produce-reports'];

  if (producePaths.some((path): boolean => state.url.startsWith(path))) {
    return true;
  }

  const router = inject(Router);

  void router.navigate(['/analytics']);

  return false;
}

/**
 * @description
 * Different roles have different access to the application
 * @param {ActivatedRouteSnapshot} _route {ActivatedRouteSnapshot} the route
 * @param {RouterStateSnapshot } state the state
 * @returns {boolean} - can access or not
 */
export default function businessUnitGuard(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  const appUserSettingService = inject(AppUserSettingService);
  const businessUnit = appUserSettingService.getBusinessUnit();

  switch (businessUnit) {
    case EBusinessUnit.QIMA_PRODUCE:
      return produceGuard(state);
    default:
      return true;
  }
}
