<div class="app-error-page">
  <img
    alt="icon"
    [src]="image"
  />
  <span class="app-error-page__title">{{ title | translate }}</span>
  <span class="app-error-page__description">{{ description | translate }}</span>
  <div class="app-error-page__buttons">
    <qima-button
      *ngIf="isGoBackHomeVisible"
      class="qima-mr-s"
      [qimaButtonLabel]="'ERROR.GO_BACK_HOME' | translate"
      [qimaButtonStyle]="qimaButton.buttonStyle.PRIMARY"
      [qimaButtonSize]="qimaButton.buttonSize.LARGE"
      [qimaButtonWidth]="qimaButton.buttonWidth.FULL"
      (qimaButtonClick)="goBackHomeClick.emit()"
    ></qima-button>

    <qima-button
      [qimaButtonLabel]="'ERROR.CONTACT_US' | translate"
      [qimaButtonStyle]="qimaButton.buttonStyle.SECONDARY"
      [qimaButtonSize]="qimaButton.buttonSize.LARGE"
      [qimaButtonWidth]="qimaButton.buttonWidth.FULL"
      (qimaButtonClick)="onContactUsClick()"
    ></qima-button>
  </div>
</div>
