export const SOLUTION_TYPE = {
  audits: 'audits',
  callbacks: 'callbacks',
  certifications: 'certifications',
  inspections: 'inspections',
  labTesting: 'lab-testing',
  selfAssessment: 'self-assessment',
  foodCertification: 'food-certification',
  training: 'training',
  translation: 'translation',
  defaultDynamicService: 'defaultDynamicService',
  debitNote: 'debitnote',
};
