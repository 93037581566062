<app-form-group
  [formGroupSize]="inputSize"
  [formGroupOrientation]="orientation"
  [formGroupLabelWidth]="labelWidth"
  [formGroupInputWidth]="inputWidth"
  [formGroupControl]="control"
  [formGroupFormGroup]="formGroup"
  [formGroupValidationMessages]="validationMessages"
  [formGroupHasDistance]="hasDistance"
>
  <app-form-label
    class="form-group-label"
    [formLabelText]="label"
    [formLabelIsRequired]="isRequired"
    [formLabelIsOptional]="isOptional"
    [formLabelTooltipLabel]="tooltipLabel"
    [formLabelTooltipPosition]="tooltipPosition"
    [formLabelVerticalAlign]="verticalAlign"
    [formLabelIsDisabled]="isLabelDisabled"
    [formLabelOrientation]="orientation"
    [formLabelLabelWidth]="labelWidth"
  >
    <div class="app-form-label-content">
      <ng-content select=".app-form-label-content"></ng-content>
    </div>
  </app-form-label>
  <ng-content class="form-group-input"></ng-content>
</app-form-group>
