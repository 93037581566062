<div
  class="app-form-label"
  [ngClass]="['app-form-label--' + verticalAlign]"
>
  <div class="app-form-label__container">
    <label
      class="app-form-label__label"
      [class.app-form-label__label--disabled]="isLabelDisabled"
      [ngClass]="['app-form-label__label--' + orientation]"
    >
      <ng-content select=".app-form-label-content"></ng-content>
      <span
        *ngIf="text"
        class="text"
        [style.--label-width]="labelWidth + 'px'"
        qimaAutoTooltip
        [qimaAutoTooltipStyle]="qimaTooltip.style.DARK"
        [qimaAutoTooltipPosition]="qimaTooltip.position.TOP_LEFT"
        [qimaAutoTooltipText]="text | translate"
        [qimaAutoTooltipStrategy]="qimaTooltip.strategy.ON_HOVER"
        [qimaAutoTooltipHasArrow]="true"
        >{{ text | translate }}</span
      >
      <span
        *ngIf="isOptional"
        class="optional"
      >
        ({{ 'COMMON.OPTIONAL' | translate }})
      </span>
    </label>
    <div class="app-form-label__required">
      <span
        *ngIf="isRequired"
        class="required-text"
        >*</span
      >
    </div>
    <div class="app-form-label__icon">
      <qima-icon
        *ngIf="tooltipLabel"
        class="info-icon"
        qimaTooltip
        [qimaTooltipStyle]="qimaTooltip.style.DARK"
        [qimaTooltipPosition]="tooltipPosition"
        [qimaTooltipTemplate]="customTemplate"
        [qimaTooltipStrategy]="qimaTooltip.strategy.ON_HOVER"
        [qimaTooltipHasArrow]="true"
        [qimaIconName]="infoIconName"
        [qimaIconSize]="qimaIcon.sizeMedium"
      ></qima-icon>
    </div>
  </div>
</div>

<ng-template #customTemplate>
  <div
    *ngIf="tooltipLabel"
    [innerHTML]="tooltipLabel | translate"
  ></div>
</ng-template>
