import { IRenewCertificateResponse } from '../../interfaces/renew-certificate-response.interface';
import { IFile } from '@aca-new/app/pages/book/pages/confirmation/shared/components/confirmation-order-summary/shared/interfaces/file.interface';
import { NpsSurveyComponent } from '@aca-new/app/pages/book/pages/confirmation/shared/components/nps-survey/nps-survey.component';
import { ENpsSurveySource } from '@aca-new/app/pages/book/pages/confirmation/shared/components/nps-survey/shaerd/enums/nps-survey-source.enum';
import { NpsSurveyService } from '@aca-new/app/pages/book/pages/confirmation/shared/components/nps-survey/shaerd/services/nps-survey.service';
import { IReportCertificateResponse } from '@aca-new/app/pages/reports/shared/components/report-decision/shared/models/interfaces/report-decision.interface';
import { IReflowDetails } from '@aca-new/app/pages/reports/shared/components/view-report/components/reflow-report/shared/interfaces/reflow-details.interface';
import { REPORTS_STATUS } from '@aca-new/app/pages/reports/shared/constants/report-status.constants';
import { DialogDeleteComponent } from '@aca-new/app/shared/components/modal/shared/components/dialog-delete/dialog-delete.component';
import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { GOOGLE_MAP_URL, SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { SOLUTION_TYPE } from '@aca-new/app/shared/constants/solution-type.constants';
import { PATHS } from '@aca-new/app/shared/constants/url-path.constants';
import { IAwsUrlListResponse } from '@aca-new/app/shared/interfaces/aws-url-list-response.interface';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { EDocType } from '@aca-new/app/shared/models/enums/doc-type.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { IDefaultSettings } from '@aca-new/app/shared/models/interfaces/default-settings.interface';
import { HttpResponseBodyNullableType, IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { IFieldDetail, IReportJson, IReportTable, IReportTableRow } from '@aca-new/app/shared/models/interfaces/report.interface';
import { QimaNullableType } from '@aca-new/app/shared/models/types/qima.type';
import { AppFileDownloadService } from '@aca-new/app/shared/services/file-services/app-file-download.service';
import { AppFileService } from '@aca-new/app/shared/services/file-services/app-file.service';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { AppSnackbarService } from '@aca-new/app/shared/services/modal-services/app-snackbar/app-snackbar.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { EQimaButtonStyle, QimaOptionalType } from '@qima/ngx-qima';
import { flattenDeep } from 'lodash/index';
import { forkJoin, lastValueFrom, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ReportService {
  public rejectReason: string = '';
  public apiURL: string = '';
  private readonly _successClassValues: Set<string> = new Set<string>(['PASSED', 'WITHIN AQL', 'OK', 'PASS', 'PASS WITH INFORMATION']);
  private readonly _dangerClassValues: Set<string> = new Set<string>(['FAILED', 'BEYOND AQL', 'NOT OK', 'FAIL', 'FAIL WITH INFORMATION', 'INCONCLUSIVE']);

  public constructor(
    @Inject(WINDOW) private readonly _window: IMyWindow,
    private readonly _httpService: HttpService,
    private readonly _appFileDownloadService: AppFileDownloadService,
    private readonly _appSnackbarService: AppSnackbarService,
    private readonly _overlayService: AppOverlayService,
    private readonly _fileService: AppFileService,
    private readonly _dialog: DialogService,
    private readonly _npsSurveyService: NpsSurveyService,
    private readonly _storageService: StorageService,
    private readonly _translateService: TranslateService,
    private readonly _appAuthenticationService: AppAuthenticationService
  ) {}

  public downloadReportsByZip(fileIds: string[], fileName: string | null): void {
    if (fileIds.length) {
      void lastValueFrom(this._appFileDownloadService.downloadFilesByZip$(fileIds.join(','), 'ClientReports', fileName));

      return;
    }

    this._overlayService.updateIsLoading(false);
    this._appSnackbarService.showSnackbar('REPORTS.REPORT_NOT_EXIST_MSG');
  }

  public showSurvey(orderNo: string, serviceType: string): void {
    this._npsSurveyService
      .getIsSurveyVisible$()
      .pipe(untilDestroyed(this))
      .subscribe((canOpen: boolean): void => {
        if (!canOpen) {
          return;
        }

        this._dialog.open(NpsSurveyComponent, {
          data: { orderNo, serviceType, npsFrom: ENpsSurveySource.DOWNLOAD_REPORT },
          stopCloseWhenClickBackdrop: true,
        });
      });
  }

  public getReportFile(downLoadReportObservable: Observable<IFile[]>[]): void {
    forkJoin(downLoadReportObservable)
      .pipe(untilDestroyed(this))
      .subscribe((results): void => {
        const files: IFile[] = flattenDeep(results) as IFile[];
        const fileIds: string[] = files.map((file): string => file?.id);

        this._appFileDownloadService.downloadDirectly(fileIds.length === 1 ? files[0].fileName : '', fileIds.join(','));
      });
  }

  public parseResult(result: string): string {
    result = result.toUpperCase();

    if (this._successClassValues.has(result)) {
      return 'REPORTS.VIEW_REPORT.PASS';
    } else if (this._dangerClassValues.has(result)) {
      return 'REPORTS.VIEW_REPORT.FAIL';
    }

    return result;
  }

  public getTextState(value: string): string {
    // check if result is number
    if (!isNaN(+value)) {
      if (+value < 5) {
        return 'text-danger';
      } else if (+value > 8) {
        return 'text-success';
      }
    } else {
      value = value.toUpperCase();

      if (this._successClassValues.has(value)) {
        return 'text-success';
      } else if (this._dangerClassValues.has(value)) {
        return 'text-danger';
      }
    }

    return 'text-warning';
  }

  public getState(data: string | number): string {
    const dataStr = data?.toString();
    let status = REPORTS_STATUS.pending;

    if (['40', '65', 'approved', 'approve', 'Approved'].includes(dataStr)) {
      status = REPORTS_STATUS.approved;
    }

    if (['50', '70', 'rejected', 'reject', 'Rejected'].includes(dataStr)) {
      status = REPORTS_STATUS.rejected;
    }

    if (['30', '60', 'pending', 'Pending'].includes(dataStr)) {
      status = REPORTS_STATUS.pending;
    }

    if (['Accepted'].includes(dataStr)) {
      status = REPORTS_STATUS.accepted;
    }

    if (['Expired'].includes(dataStr)) {
      status = REPORTS_STATUS.expired;
    }

    if (['Invited'].includes(dataStr)) {
      status = REPORTS_STATUS.invited;
    }

    return status;
  }

  public getReasonState(reason: string): string {
    const reasons: string[] = reason.split(';');

    if (reasons.length > 1) {
      const numberedReasons: string[] = reasons.map((value: string, idx: number): string => `${idx + 1}. ${value}`);

      return numberedReasons.join('<br>');
    }

    return reason;
  }

  public getReport$(productId: string, suffix: string = ''): Observable<QimaNullableType<IReportTable>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const url: string = `${SERVER_URL}/user/${userId}/reports?page-size=5&page=1&keyword=${productId}${suffix}`;

    return this._httpService.httpClient
      .get<IHttpResponseBody<QimaNullableType<IReportTable>>>(url, {
        observe: 'response',
      })
      .pipe(
        map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<IReportTable>>>>): QimaNullableType<IReportTable> => {
          const result = response?.body?.content || null;

          // to use correct spelling in frontend
          result?.pageItems.forEach((r: IReportTableRow): void => {
            r.overallResult ??= r.overrallResult;
          });

          return result;
        })
      );
  }

  public updateReport$(decision: IReportCertificateResponse): Observable<HttpResponseBodyNullableType<string>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .put<HttpResponseBodyNullableType<string>>(
        `${SERVER_URL}/user/${userId}/report`,
        {
          ...decision,
        },
        { observe: 'body' }
      )
      .pipe(map((response: HttpResponseBodyNullableType<string>): HttpResponseBodyNullableType<string> => response));
  }

  public undoReport$(productId: string): Observable<void> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient.put<void>(`${SERVER_URL}/user/${userId}/report/${productId}/undone`, {}, { observe: 'body' }).pipe(map((response: void): void => response));
  }

  public getReportJson$(productId: string, orderId: string): Observable<QimaNullableType<IReportJson>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient.get<IHttpResponseBody<QimaNullableType<IReportJson>>>(`${SERVER_URL}/user/${userId}/report/${productId}`, { params: { orderId } }).pipe(
      map((response: IHttpResponseBody<QimaNullableType<IReportJson>>): QimaNullableType<IReportJson> => {
        const result = response?.content || null;

        // to use correct spelling in frontend
        result && (result.reportDetailBean.report.overallResult = result?.reportDetailBean.report.overrallResult);

        return result;
      })
    );
  }

  public getReportJsonByToken$(token: string): Observable<IHttpResponseBody<IReportJson>> {
    return this._httpService.httpClient.get<IHttpResponseBody<IReportJson>>(`${SERVER_URL}${PATHS.externalReport}${token}`).pipe(
      map((response: IHttpResponseBody<IReportJson>): IHttpResponseBody<IReportJson> => {
        // to use correct spelling in frontend
        response?.content && (response.content.reportDetailBean.report.overallResult = response.content.reportDetailBean.report.overrallResult);

        return response;
      })
    );
  }

  // TODO: this is a generic method to fetch temp access, should be moved to a service for login functions
  public getAccessToken$(token: string, code: string): Observable<IHttpResponseBody<Record<string, string>>> {
    return this._httpService.httpClient.post<IHttpResponseBody<Record<string, string>>>(`${SERVER_URL}/access/token`, {
      token,
      code,
    });
  }

  public onLocationClick(position: string): void {
    const mapUrl = GOOGLE_MAP_URL.replace('{location}', position);

    this._window.open(mapUrl, '_blank');
  }

  public downloadZipImages(productIds: string[]): void {
    const downLoadZipImageObservable: Observable<QimaOptionalType<IFile[]>>[] = productIds.map(
      (productId: string): Observable<QimaOptionalType<IFile[]>> => this._fileService.getFiles$(productId, EDocType.REPORT_IMAGE_ZIP)
    );

    this._overlayService.updateIsLoading(true);
    forkJoin(downLoadZipImageObservable)
      .pipe(
        filter((response): boolean => {
          if (response.some((item): boolean => (item ? !item.length : false))) {
            this._dialog.open(DialogDeleteComponent, {
              data: {
                title: this._translateService.instant('REPORTS.VIEW_REPORT.GET_ALL_PICTURE', { quantity: '' }),
                content: 'REPORTS.ZIP_MULTI_WAITING_MESSAGE',
                hideLeftButton: true,
                deleteTitle: 'COMMON.CONTACT_SUPPORT_TEAM',
                buttonStyle: EQimaButtonStyle.PRIMARY,
              },
            });
            this._overlayService.updateIsLoading(false);

            return false;
          }

          return true;
        }),
        switchMap((_): Observable<HttpResponseBodyNullableType<IAwsUrlListResponse>> => {
          return this._fileService.getAwsLink$(productIds);
        }),
        untilDestroyed(this)
      )
      .subscribe((awsUrlList): void => {
        this._overlayService.updateIsLoading(false);

        awsUrlList?.content?.default?.forEach((link: string): void => {
          this._window.open(link);
        });
      });
  }

  public deleteReports$(ids: string[]): Observable<void> {
    const customerId = this._appAuthenticationService.getCompanyId();

    return this._httpService.httpClient
      .delete<void>(`${SERVER_URL}/v1.0/food-certification/customer/${customerId}/documents`, {
        body: ids,
        observe: 'body',
      })
      .pipe(map((response: void): void => response));
  }

  public getDefaultSettings$(serviceType: number): Observable<HttpResponseBodyNullableType<IDefaultSettings>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const url: string = `${SERVER_URL}/v1.0/service/user/${userId}/${serviceType}/default-settings`;

    return this._httpService.httpClient
      .get<HttpResponseBodyNullableType<IDefaultSettings>>(url, {
        observe: 'body',
      })
      .pipe(map((response: Readonly<HttpResponseBodyNullableType<IDefaultSettings>>): HttpResponseBodyNullableType<IDefaultSettings> => response));
  }

  public getFieldDetailsByServiceSimpleName$(orderId: string, serviceSimpleName: string): Observable<IFieldDetail[] | null> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const url: string = `${SERVER_URL}/v1.0/report/${orderId}/user/${userId}?serviceSimpleName=${serviceSimpleName}`;

    return this._httpService.httpClient
      .get<IHttpResponseBody<IFieldDetail[]>>(url, {
        observe: 'body',
      })
      .pipe(map((response: Readonly<IHttpResponseBody<IFieldDetail[]>>): IFieldDetail[] | null => response.content || null));
  }

  public getReflowDetails$(orderId: string): Observable<QimaOptionalType<IReflowDetails>> {
    const url: string = `${SERVER_URL}/reflow/audit/${orderId}`;

    return this._httpService.httpClient
      .get<IHttpResponseBody<IReflowDetails>>(url, {
        observe: 'body',
      })
      .pipe(map((response: Readonly<IHttpResponseBody<QimaOptionalType<IReflowDetails>>>): QimaOptionalType<IReflowDetails> => response.content));
  }

  public markReportAsRead$(id: string, apiEndpoint: string): Observable<void> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .post<void>(
        `${SERVER_URL}/user/${userId}/${apiEndpoint}/${id}/read`,
        {
          data: {},
        },
        { observe: 'body' }
      )
      .pipe(map((response: void): void => response));
  }

  public getPdfInfoFiles$(id: string, reportType: string): Observable<IFile[]> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const apiEndpoint = reportType === SOLUTION_TYPE.audits ? 'audit-report' : 'report';
    let url = `${SERVER_URL}/user/${userId}/${apiEndpoint}/${id}/pdf-info`;

    if ([SOLUTION_TYPE.selfAssessment, SOLUTION_TYPE.callbacks, SOLUTION_TYPE.defaultDynamicService].includes(reportType)) {
      url += '?isSingleFile=true';
    }

    return this._httpService.httpClient.get<IHttpResponseBody<IFile[]>>(url, { observe: 'body' }).pipe(
      map((response: QimaNullableType<IHttpResponseBody<IFile[]>>): IFile[] => {
        this.markReportAsRead$(id, apiEndpoint).pipe(untilDestroyed(this)).subscribe();

        return response?.content ?? [];
      })
    );
  }

  public renewCertificate$(orderId: string): Observable<IRenewCertificateResponse> {
    const url = `${SERVER_URL}/v1.0/food-certification/recertify?from-order-id=${orderId}&service-type=ggap`;

    return this._httpService.httpClient.post<IRenewCertificateResponse>(url, null).pipe(map((response: IRenewCertificateResponse): IRenewCertificateResponse => response));
  }
}
