import { ESortOrder } from '@aca-new/app/shared/components/data-table/shared/models/enums/sort-order.enum';
import { IFrontSort } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-sort.interface';
import { ETableDataType } from '@aca-new/app/shared/components/table/shared/enums/table-data-type.enum';
import { ITableBodyCell, ITableBodyRow } from '@aca-new/app/shared/components/table/shared/interfaces/table-data.interface';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { QimaOptionalType } from '@qima/ngx-qima';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
@UntilDestroy()
export class DataTableService {
  public readonly tableData$: BehaviorSubject<ITableBodyRow[]> = new BehaviorSubject<ITableBodyRow[]>([]);
  public readonly pageIndex$: BehaviorSubject<QimaOptionalType<number>> = new BehaviorSubject<QimaOptionalType<number>>(undefined);
  public readonly pageSize$: BehaviorSubject<QimaOptionalType<number>> = new BehaviorSubject<QimaOptionalType<number>>(undefined);
  public readonly sort$: BehaviorSubject<QimaOptionalType<IFrontSort>> = new BehaviorSubject<QimaOptionalType<IFrontSort>>(undefined);
  public readonly searchValue$: BehaviorSubject<QimaOptionalType<string>> = new BehaviorSubject<QimaOptionalType<string>>(undefined);
  public readonly isAllChecked$: Subject<boolean> = new Subject<boolean>();
  // TODO handle filter
  public readonly filter$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public tableTotalSize: number = 0;
  public apiURL: string = '';

  public dataTableSubject$ = combineLatest([this.tableData$, this.sort$, this.filter$, this.pageIndex$, this.pageSize$, this.searchValue$]).pipe(
    map(([tableData, sort, _filter, pageIndex, pageSize, searchValue]): ITableBodyRow[] => {
      // TODO handle table filter on front
      // front search
      tableData = this._searchOnFront(tableData, searchValue);

      // front sort
      this._sortOnFront(tableData, sort);

      // set total size
      this.tableTotalSize = tableData?.length;

      // front pagination
      if (pageIndex && pageSize) {
        tableData = tableData.slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
      }

      return tableData;
    })
  );

  public updateTableData(tableData: ITableBodyRow[]): void {
    this.tableData$.next(tableData);
  }

  public updatePagination(pageIndex: number, pageSize: number): void {
    this.pageIndex$.next(pageIndex);
    this.pageSize$.next(pageSize);
  }

  public updatePageIndex(pageIndex: number): void {
    this.pageIndex$.next(pageIndex);
  }

  public updatePageSize(pageSize: number): void {
    this.pageSize$.next(pageSize);
  }

  public updateSort(sortData: QimaOptionalType<IFrontSort>): void {
    this.sort$.next(sortData);
  }

  public updateFilter(str: string): void {
    this.filter$.next(str);
  }

  public updateSearchValue(value: string): void {
    this.searchValue$.next(value);
  }

  public updateIsAllChecked(isAllChecked: boolean): void {
    this.isAllChecked$.next(isAllChecked);
  }

  private _searchOnFront(tableData: ITableBodyRow[], searchValue: QimaOptionalType<string>): ITableBodyRow[] {
    if (searchValue) {
      tableData = tableData.filter((row: ITableBodyRow): boolean => {
        return !!row.viewData.find((cell: ITableBodyCell): boolean => {
          if (cell.type === ETableDataType.CUSTOMIZED) {
            const data = cell.customizedCellConfiguration?.data;

            if (data) {
              return Object.keys(data).some((key): boolean => (data as Record<string, object>)[key]?.toString().toUpperCase().includes(searchValue.toUpperCase()));
            }
          }

          return !!cell.label?.toUpperCase()?.includes(searchValue.toUpperCase());
        });
      });
    }

    return tableData;
  }

  private _sortOnFront(tableData: ITableBodyRow[], sort: QimaOptionalType<IFrontSort>): void {
    if (!sort) {
      return;
    }

    tableData.sort((s1, s2): number => {
      const item1 = s1.viewData[sort.index];
      const item2 = s2.viewData[sort.index];

      if (sort.sortFunction) {
        return sort.sortFunction(item1, item2, sort.order);
      }

      return this._generalSort(item1, item2, sort.order);
    });
  }

  private _generalSort(item1: ITableBodyCell, item2: ITableBodyCell, order: ESortOrder): number {
    let data1;
    let data2;

    switch (item1.type) {
      case ETableDataType.DATE:
        data1 = new Date(item1.label ?? '').getTime();
        data2 = new Date(item2.label ?? '').getTime();

        if (order === ESortOrder.ASC) {
          return data1 < data2 ? -1 : 1;
        }

        return data2 < data1 ? -1 : 1;

      case ETableDataType.NUMBER:
        data1 = +(item1.label ?? 0);
        data2 = +(item2.label ?? 0);

        if (order === ESortOrder.ASC) {
          return data1 < data2 ? -1 : 1;
        }

        return data2 < data1 ? -1 : 1;

      case ETableDataType.BADGE:
      case ETableDataType.DOT_LABEL:
      case ETableDataType.ICE_CUBE:
      case ETableDataType.LINK:
      case ETableDataType.MULTIPLE_LABEL_CELL:
      case ETableDataType.STRING:
        data1 = item1.label ?? '';
        data2 = item2.label ?? '';

        if (order === ESortOrder.ASC) {
          return data1.toUpperCase() < data2.toUpperCase() ? -1 : 1;
        }

        return data2.toUpperCase() < data1.toUpperCase() ? -1 : 1;
    }

    return 1;
  }
}
