import { ErrorPageDialogComponent } from '@aca-new/app/shared/components/error-page/shared/error-page-dialog/error-page-dialog.component';
import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { IMAGES } from '@aca-new/app/shared/constants/images.constants';
import { IHttpErrorHandler } from '@aca-new/app/shared/interfaces/http-error-handler.interface';
import { EPostMessageType } from '@aca-new/app/shared/models/enums/post-message-type.enum';
import { AcaMessageService } from '@aca-new/app/shared/services/post-message-services/aca-message/aca-message.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QimaOptionalType } from '@qima/ngx-qima';

@UntilDestroy()
@Injectable()
export class HttpErrorHandlerService implements IHttpErrorHandler {
  private _dialogRef: QimaOptionalType<DialogRef<unknown>> = undefined;
  private _errorMessage: string = '';

  public constructor(
    private readonly _dialog: DialogService,
    private readonly _appAuthenticationService: AppAuthenticationService,
    private readonly _acaMessageService: AcaMessageService,
    protected readonly _router: Router
  ) {}

  public unauthorized(): void {
    this._appAuthenticationService.logout();
  }

  public forbidden(error: Readonly<HttpErrorResponse>): void {
    this._openConfirmModal('ERROR.ACCESS_DENIED', 'ERROR.ACCESS_DENIED_DESCRIPTION', IMAGES.forbidden)
      .afterClosed$()
      .pipe(untilDestroyed(this))
      .subscribe((isConfirmed): void => {
        if (error.message !== 'DATA_FORBIDDEN') {
          this._acaMessageService.postMessageToAca({
            type: EPostMessageType.LOGOUT,
          });
          this._appAuthenticationService.navigateToLogout();
        } else if (isConfirmed) {
          void this._router.navigate(['/home']);
        }
      });
  }

  public internalServerError(): void {
    this._openConfirmModal('ERROR.ERROR_PAGE', 'ERROR.ERROR_PAGE_DESCRIPTION', IMAGES.internalServerError)
      .afterClosed$()
      .pipe(untilDestroyed(this))
      .subscribe((isConfirmed): void => {
        if (isConfirmed) {
          void this._router.navigate(['/home']);
        }
      });
  }

  public notFound(): void {
    this._openConfirmModal('ERROR.PAGE_NOT_FOUND', 'ERROR.PAGE_NOT_FOUND_DESCRIPTION', IMAGES.pageNotFound)
      .afterClosed$()
      .pipe(untilDestroyed(this))
      .subscribe((isConfirmed): void => {
        if (isConfirmed) {
          void this._router.navigate(['/home']);
        }
      });
  }

  public get errorMessage(): string {
    return this._errorMessage;
  }

  public setErrorMessage(errorMessage: string): void {
    this._errorMessage = errorMessage;
  }

  private _openConfirmModal(title: Readonly<string>, description: Readonly<string>, image: string, isGoBackHomeVisible: boolean = true): DialogRef<unknown> {
    if (this._dialogRef) {
      this._dialogRef.close('');
    }

    this._dialogRef = this._dialog.open(ErrorPageDialogComponent, {
      data: {
        description,
        image,
        isGoBackHomeVisible,
        title,
      },
      stopCloseWhenClickBackdrop: true,
    });

    return this._dialogRef;
  }
}
