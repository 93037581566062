<div class="pay-by-transfer-details">
  <div class="pay-by-transfer-details__title">{{ 'PAY_BY_TRANSFER.PAYMENT_DETAILS' | translate }}</div>
  <div class="pay-by-transfer-details__container">
    <ng-container
      *ngFor="let fee of orderFees"
      appNgForTrackByUuid
    >
      <ng-container *ngTemplateOutlet="feeItem; context: { fee: fee }"></ng-container>
      <ng-container *ngIf="fee.fees && fee.fees.length">
        <ng-container
          *ngFor="let subFee of fee.fees"
          appNgForTrackByUuid
        >
          <ng-container *ngTemplateOutlet="feeItem; context: { fee: subFee }"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngTemplateOutlet="totalItem; context: { fee: totalFees }"></ng-container>
</div>
<ng-template
  #feeItem
  let-fee="fee"
>
  <div
    *ngIf="fee.orderId"
    class="order-fee"
  >
    <span class="order-fee__item-name">{{ fee.itemName }}</span>
    <div class="order-fee__amount">
      <span>{{ fee.amountUnit }}</span>
      <span>{{ fee.amount | number: '1.2' }}</span>
    </div>
  </div>
</ng-template>

<ng-template
  #totalItem
  let-fee="fee"
>
  <div
    *ngIf="fee"
    class="order-fee order-fee--sticky"
  >
    <span class="order-fee__total-name">{{ 'PAYMENTS.TOTAL' | translate }}</span>
    <div class="order-fee__amount qima-semi-bold">
      <span>{{ totalFeesUnit }}</span>
      <span>{{ fee | number: '1.2' }}</span>
    </div>
  </div>
</ng-template>
