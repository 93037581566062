<div class="dialog-export">
  <app-dialog-common [dialogCommonTitle]="dialogData.title">
    <div
      class="content-export"
      [formGroup]="form"
    >
      <app-form-item
        formItemLabel="EXPORT.START_SERVICE_DATE"
        [formItemControl]="form.controls.startDate"
        [formItemOrientation]="appForm.formItemOrientation.VERTICAL"
      >
        <qima-input-date
          formControlName="startDate"
          [qimaInputDateSize]="qimaInput.inputSize.LARGE"
          [qimaInputDateHasValidators]="true"
          (qimaInputDateDateClick)="startDateChange($event)"
        ></qima-input-date>
      </app-form-item>
      <app-form-item
        formItemLabel="EXPORT.END_SERVICE_DATE"
        [formItemControl]="form.controls.endDate"
        [formItemOrientation]="appForm.formItemOrientation.VERTICAL"
      >
        <qima-input-date
          formControlName="endDate"
          [qimaInputDateSize]="qimaInput.inputSize.LARGE"
          [qimaInputDateHasValidators]="true"
          [qimaInputDateMinimum]="endMinimumDate"
        ></qima-input-date>
      </app-form-item>
      <qima-block-banner
        *ngIf="form.errors?.timeRangeExceedsOneYear"
        [qimaBlockBannerType]="qimaBlockBannerType"
        [qimaBlockBannerMessage]="'EXPORT.TIME_RANGE_EXCEEDS_ONE_YEAR' | translate"
      ></qima-block-banner>
    </div>
    <div class="foot-export">
      <qima-button
        [qimaButtonStyle]="qimaButton.buttonStyle.SECONDARY"
        [qimaButtonSize]="qimaButton.buttonSize.LARGE"
        [qimaButtonLabel]="'EXPORT.CANCEL' | translate"
        (qimaButtonClick)="close()"
      ></qima-button>
      <qima-button
        class="qima-ml-xs"
        [qimaButtonIsDisabled]="form.invalid"
        [qimaButtonSize]="qimaButton.buttonSize.LARGE"
        [qimaButtonLabel]="'EXPORT.EXPORT_NOW' | translate"
        (qimaButtonClick)="export()"
      ></qima-button>
    </div>
  </app-dialog-common>
</div>
