import { EFormItemVerticalAlign } from '@aca-new/app/shared/components/form-item/shared/models/enums/form-item-vertical-align.enum';
import { EOrientation } from '@aca-new/app/shared/models/enums/orientation.enum';
import { QimaIconService } from '@aca-new/app/shared/services/exported-services/qima-icon/qima-icon.service';
import { QimaTooltipService } from '@aca-new/app/shared/services/exported-services/qima-tooltip/qima-tooltip.service';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { EQimaIconName, EQimaPosition, QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'app-form-label',
  templateUrl: './form-label.component.html',
  styleUrls: ['./form-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormLabelComponent {
  /**
   * @description
   * The form label vertical align
   * @type {QimaOptionalType<EFormItemVerticalAlign>}
   * @default undefined
   */
  @Input('formLabelVerticalAlign')
  public verticalAlign: QimaOptionalType<EFormItemVerticalAlign> = undefined;

  /**
   * @description
   * The placement of form label and form input
   * @type {EOrientation}
   * @default {@link EOrientation.HORIZONTAL}
   */
  @Input('formLabelOrientation')
  public orientation: EOrientation = EOrientation.HORIZONTAL;

  /**
   * @description
   * The label for display
   * @type {QimaOptionalType<string>}
   * @default ''
   */
  @Input('formLabelText')
  public text: QimaOptionalType<string> = '';

  /**
   * @description
   * The label is disabled
   * @type {boolean}
   * @default false
   */
  @Input('formLabelIsDisabled')
  public isLabelDisabled: boolean = false;

  /**
   * @description
   * Show or hide the required icon
   * @type {boolean}
   * @default false
   */
  @Input('formLabelIsRequired')
  public isRequired: boolean = false;

  /**
   * @description
   * Show or hide the optional text
   * @type {boolean}
   * @default false
   */
  @Input('formLabelIsOptional')
  public isOptional: boolean = false;

  /**
   * @description
   * The label of the tooltip
   * @type {QimaOptionalType<string>}
   * @default undefined
   */
  @Input('formLabelTooltipLabel')
  public tooltipLabel: QimaOptionalType<string> = undefined;

  /**
   * @description
   * The width of the label
   * @type {number | 'auto'}
   * @default 'auto'
   */
  @Input('formLabelLabelWidth')
  public labelWidth: number | string = 'auto';

  /**
   * @description
   * The position of the tooltip
   * @type {EQimaPosition}
   * @default EQimaPosition.TOP_LEFT
   */
  @Input('formLabelTooltipPosition')
  public tooltipPosition: EQimaPosition = EQimaPosition.TOP_LEFT;

  public infoIconName: EQimaIconName = EQimaIconName.INFO;

  public constructor(
    public readonly qimaIcon: QimaIconService,
    public readonly qimaTooltip: QimaTooltipService
  ) {}
}
