import { IAdvancePayment } from '../models/interfaces/confirmation.interface';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationCommonService {
  public readonly isInvoiceButtonsVisible$: Subject<boolean> = new Subject<boolean>();
  public readonly canGenerateProformaInvoice$: Subject<boolean> = new Subject<boolean>();
  public readonly canReissueProformaInvoice$: Subject<boolean> = new Subject<boolean>();
  public readonly advancePayment$: Subject<IAdvancePayment> = new Subject<IAdvancePayment>();
  public readonly openNpsDialog$: Subject<string> = new Subject<string>();
  public readonly getOrderDetails$: Subject<void> = new Subject<void>();

  public updateIsInvoiceButtonsVisible(isVisible: boolean): void {
    this.isInvoiceButtonsVisible$.next(isVisible);
  }

  public updateCanGenerateProformaInvoice(canGenerate: boolean): void {
    this.canGenerateProformaInvoice$.next(canGenerate);
  }

  public updateGetOrderDetails(): void {
    this.getOrderDetails$.next();
  }

  public updateCanReissueProformaInvoice(canReissue: boolean): void {
    this.canReissueProformaInvoice$.next(canReissue);
  }

  public updateAdvancePayment(advancePayment: IAdvancePayment): void {
    this.advancePayment$.next(advancePayment);
  }

  public openNpsDialog(orderNo: string): void {
    this.openNpsDialog$.next(orderNo);
  }
}
