<span
  (animationend)="onAnimationEnd()"
  [@qimaAccordion]="!!validationMessage"
  class="form-validation-message"
  [innerHTML]="
    renderedHtml ?? ''
      | translate
        : {
            translateKey: translateValue
          }
  "
></span>
