<div class="pay-by-transfer-dialog">
  <app-dialog-header (dialogHeaderClose)="close()">
    <span class="pay-by-transfer-dialog__title">
      {{ 'PAY_BY_TRANSFER.TITLE' | translate }}
    </span>
  </app-dialog-header>
  <div class="pay-by-transfer-dialog__container">
    <div class="pay-by-transfer-dialog__container__step">
      <span>{{ 'PAY_BY_TRANSFER.STEP_1' | translate }}</span>
      <qima-button
        [qimaButtonLabel]="'PAY_BY_TRANSFER.DOWNLOAD_TIP' | translate"
        [qimaButtonStyle]="qimaButton.buttonStyle.TERTIARY"
        [qimaButtonSize]="qimaButton.buttonSize.LARGE"
        [qimaButtonIconName]="qimaIcon.iconName.DOWNLOAD_ATTACHMENTS"
        [qimaButtonIconPosition]="qimaButton.buttonIconPosition.LEFT"
        (qimaButtonClick)="onPdfDownloadClick()"
      ></qima-button>
    </div>
    <div class="pay-by-transfer-dialog__container__description qima-mb-m">{{ 'PAY_BY_TRANSFER.STEP_1_description' | translate }}</div>
    <div class="pay-by-transfer-dialog__container__payment-info">
      <div class="pay-by-transfer-dialog__container__payment-info__bank">
        <div
          *ngFor="let item of bankInfo; let index = index"
          appNgForTrackByUuid
          class="app-flex app-justify-content-between"
          [ngClass]="{ 'qima-mt-s': index !== 0 }"
        >
          <span class="key">{{ item.key | translate }}</span>
          <div class="value">
            <span>{{ item.value }}</span>
            <app-clipboard
              *ngIf="item.canCopy"
              class="qima-ml-base"
              [clipboardTextToCopy]="item.value"
            ></app-clipboard>
          </div>
        </div>
      </div>
      <app-pay-by-transfer-details
        [payByTransferDetailsOrderFees]="orderFees"
        [payByTransferDetailsTotalFeesUnit]="totalFeesUnit"
        [payByTransferDetailsTotalFees]="totalFees"
      ></app-pay-by-transfer-details>
    </div>
    <div class="pay-by-transfer-dialog__container__step qima-mt-l">
      <span>{{ 'PAY_BY_TRANSFER.STEP_2' | translate }}</span>
    </div>

    <div class="pay-by-transfer-dialog__container__description qima-mb-s">
      {{ 'PAY_BY_TRANSFER.STEP_2_description' | translate }}
    </div>

    <div class="pay-by-transfer-dialog__container__pay-item__proof__drop-file__operation">
      <qima-input-file-select
        class="upload-file-style"
        [qimaInputFileSelectLabel]="'PAY_BY_TRANSFER.UPLOAD_FILE_TIP' | translate"
        [qimaInputFileSelectIconName]="qimaIcon.iconName.ATTACHMENT"
        [qimaInputFileSelectIsMultiple]="true"
        (qimaInputFileSelectFilesChange)="onFileSelectFilesChange($event)"
        [qimaInputFileSelectAccept]="fileSelectAccept"
      ></qima-input-file-select>
    </div>
    <div
      class="pay-by-transfer-dialog__container__pay-item__proof__drop-file__tips"
      [innerHTML]="'PAY_BY_TRANSFER.UPLOAD_FILE_TIP_CONTRACT' | translate"
    ></div>
    <div
      *ngIf="uploadFiles"
      class="pay-by-transfer-dialog__container__pay-item__proof__drop-file__shows"
    >
      <div
        *ngFor="let file of uploadFiles; let idx = index; let last = last"
        appNgForTrackByUuid
        class="pay-by-transfer-dialog__container__pay-item__proof__drop-file__shows__item"
        [ngClass]="{ 'qima-mt-l': idx === 0, 'qima-mb-l': !last }"
      >
        <qima-file
          [qimaFileName]="file.name"
          [qimaFileSize]="file.size"
          [qimaFileUploadedSize]="file.size"
          [qimaFileMimeType]="qimaMimeType.APPLICATION_PDF"
          (qimaFileRemove)="onRemoveClick(file, idx)"
        >
        </qima-file>
      </div>
    </div>
  </div>
  <div class="pay-by-transfer-dialog__footer">
    <div>
      <qima-button
        class="qima-mr-xs"
        [qimaButtonLabel]="'PAY_BY_TRANSFER.SUBMIT_NOW' | translate"
        [qimaButtonSize]="qimaButton.buttonSize.LARGE"
        [qimaButtonWidth]="qimaButton.buttonWidth.FULL"
        [qimaButtonIsDisabled]="uploadFiles.length === 0"
        (qimaButtonClick)="onSubmit()"
      ></qima-button>
    </div>
    <div>
      <qima-button
        class="qima-mr-xs"
        [qimaButtonLabel]="'PAY_BY_TRANSFER.SUBMIT_LATER' | translate"
        [qimaButtonStyle]="qimaButton.buttonStyle.SECONDARY"
        [qimaButtonSize]="qimaButton.buttonSize.LARGE"
        [qimaButtonWidth]="qimaButton.buttonWidth.FULL"
        (qimaButtonClick)="close()"
      ></qima-button>
    </div>
  </div>
</div>
