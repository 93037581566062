import { FormLabelComponent } from '@aca-new/app/shared/components/form-label/form-label.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QimaAutoTooltipDirectiveModule, QimaIconModule, QimaTooltipDirectiveModule } from '@qima/ngx-qima';

@NgModule({
  declarations: [FormLabelComponent],
  exports: [FormLabelComponent],
  imports: [QimaIconModule, CommonModule, TranslateModule, QimaTooltipDirectiveModule, QimaAutoTooltipDirectiveModule],
})
export class FormLabelModule {}
