import { EValidationKey } from '@aca-new/app/shared/components/form-validation-message/shared/models/enums/validation-key.enum';
import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QIMA_QUICK_ACCORDION_ANIMATION, QimaOptionalType } from '@qima/ngx-qima';
import { merge } from 'rxjs';

interface ICommonValidation {
  message: string;
  value: string;
}

@UntilDestroy()
@Component({
  selector: 'app-form-validation-message',
  templateUrl: './form-validation-message.component.html',
  styleUrls: ['./form-validation-message.component.scss'],
  animations: [QIMA_QUICK_ACCORDION_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormValidationMessageComponent<T1, T2 extends Record<keyof T2, unknown>> implements AfterContentInit {
  /**
   * @description
   * The validation messages
   * @type {QimaOptionalType<Record<string, string>>}
   * @default undefined
   */
  @Input('formValidationMessages')
  public validationMessages: QimaOptionalType<Record<string, string>> = undefined;

  /**
   * @description
   * The control of form item
   * @type {QimaOptionalType<FormControl>}
   * @default undefined
   */
  @Input('formValidationMessageControl')
  public control: QimaOptionalType<FormControl<T1>> = undefined;

  /**
   * @description
   * The form group which contains this control
   * @type {QimaOptionalType<FormGroup<ControlsOf>>}
   * @default undefined
   */
  @Input('formValidationFormGroup')
  public formGroup: QimaOptionalType<FormGroup<ControlsOf<T2>>> = undefined;

  public validationMessage: string | undefined = undefined;
  public renderedHtml: string | undefined = undefined;
  public translateValue: string = '';
  public constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {}

  public ngAfterContentInit(): void {
    this._initValidation();
  }

  public onAnimationEnd(): void {
    this.renderedHtml = this.validationMessage;
    this._changeDetectorRef.markForCheck();
  }

  private _initValidation(): void {
    const { control, formGroup } = this;

    this._initValidationMessages();

    if (control) {
      this._validateByFormControl();

      return;
    }

    if (formGroup) {
      this._validateByFormGroup();
    }
  }

  private _initValidationMessages(): void {
    const defaultValidationMessages = {
      addressMinLength: 'VALIDATIONS.ADDRESS_MIN_LENGTH',
      addressCharactersType: 'VALIDATIONS.ADDRESS_CHARACTERS_TYPE',
      required: 'COMMON.REQUIRED',
      naturalNumbers: 'COMMON.NATURAL_NUMBER',
      maxlength: 'COMMON.MAXLENGTH',
      unicodeCharacter: 'COMMON.UNICODE_CHARACTER',
      max: 'COMMON.MAX',
      min: 'COMMON.MIN',
      email: 'COMMON.INVALID_EMAIL',
      positiveIntegerOrZero: 'COMMON.POSITIVE_INTEGER',
      invalidNumber: 'COMMON.INVALID_NUMBER',
    };

    if (!this.validationMessages) {
      this.validationMessages = {};
    }

    this.validationMessages = Object.assign(defaultValidationMessages, this.validationMessages);
  }

  // for single validation
  private _validateByFormControl(): void {
    const { control } = this;

    if (!control) {
      return;
    }

    const watch$ = merge(control.touch$, control.valueChanges);

    watch$.pipe(untilDestroyed(this)).subscribe((): void => {
      this._setMessage(control, this.validationMessages as Record<string, string>);
    });
  }

  // for crossing validation
  private _validateByFormGroup(): void {
    const { formGroup } = this;

    if (!formGroup) {
      return;
    }

    const watch$ = merge(formGroup.touch$, formGroup.valueChanges);

    watch$.pipe(untilDestroyed(this)).subscribe((): void => {
      this._setMessage(formGroup, this.validationMessages as Record<string, string>);
    });
  }

  private _setMessage(control: FormControl<T1> | FormGroup<ControlsOf<T2>>, validationMessages: Record<string, string>): void {
    this.validationMessage = undefined;

    if ((control.touched || control.dirty) && control.errors) {
      this.validationMessage = validationMessages[Object.keys(control.errors)[0]];

      if (Object.keys(control.errors)[0] === EValidationKey.COMMON) {
        this.validationMessage = (control.errors[EValidationKey.COMMON] as ICommonValidation).message;
        this.translateValue = (control.errors[EValidationKey.COMMON] as ICommonValidation).value;
      }

      this.renderedHtml = this.validationMessage;
    }

    this._changeDetectorRef.markForCheck();
  }
}
