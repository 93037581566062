import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IExport {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  xlsx_base64: string;
}

@Injectable()
export class DialogExportService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public export$(url: string): Observable<IExport> {
    return this._httpClient.get<IExport>(url, { observe: 'body' }).pipe(map((response: Readonly<IExport>): IExport => response));
  }
}
