import { IOrderFee } from '@aca-new/app/pages/payments/pages/payments-content/shared/models/interfaces/order-fee.interface';
import { TrackByUuidDirective } from '@aca-new/app/shared/directives/track-by-uuid.directive';
import { ECurrency } from '@aca-new/app/shared/models/enums/currency.enum';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-pay-by-transfer-details',
  templateUrl: './pay-by-transfer-details.component.html',
  styleUrls: ['./pay-by-transfer-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TrackByUuidDirective, NgTemplateOutlet, TranslateModule],
})
export class PayByTransferDetailsComponent {
  /**
   * @description
   * Order fees
   * @type {IOrderFee[]}
   * @default []
   */
  @Input('payByTransferDetailsOrderFees')
  public orderFees: IOrderFee[] = [];

  /**
   * @description
   * Total fees unit
   * @type {string}
   * @default ECurrency.USD
   */
  @Input('payByTransferDetailsTotalFeesUnit')
  public totalFeesUnit: string = ECurrency.USD;

  /**
   * @description
   * Total fees
   * @type {number}
   * @default 0
   */
  @Input('payByTransferDetailsTotalFees')
  public totalFees: number = 0;
}
