import { HeaderComponent } from '@aca-new/app/pages/main/shared/components/header/header.component';
import { AccountMenuComponent } from '@aca-new/app/pages/main/shared/components/header/shared/components/account-menu/account-menu.component';
import { DialogCommonModule } from '@aca-new/app/shared/components/modal/shared/components/dialog-common/dialog-common.module';
import { TranslateMenuModule } from '@aca-new/app/shared/components/translate-menu/translate-menu.module';
import { TrackByUuidDirective } from '@aca-new/app/shared/directives/track-by-uuid.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  QimaAutoTooltipDirectiveModule,
  QimaAvatarFakeModule,
  QimaAvatarModule,
  QimaButtonIconModule,
  QimaButtonModule,
  QimaDividerModule,
  QimaIconModule,
  QimaMenuItemTextModule,
  QimaMenuModule,
  QimaShadowDirectiveModule,
  QimaTooltipDirectiveModule,
} from '@qima/ngx-qima';

@NgModule({
  declarations: [HeaderComponent, AccountMenuComponent],
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    TranslateModule,
    QimaMenuModule,
    QimaMenuItemTextModule,
    QimaDividerModule,
    QimaIconModule,
    QimaAvatarModule,
    RouterModule,
    OverlayModule,
    QimaButtonIconModule,
    DialogCommonModule,
    QimaButtonModule,
    QimaAvatarFakeModule,
    TranslateMenuModule,
    QimaShadowDirectiveModule,
    QimaAutoTooltipDirectiveModule,
    QimaTooltipDirectiveModule,
    TrackByUuidDirective,
  ],
})
export class HeaderModule {}
