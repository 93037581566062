<div
  [ngClass]="['app-table--' + layoutStyle]"
  [style.bottom.px]="layoutStyle === appTable.layoutStyle.ABSOLUTE_WITH_TOP_BOTTOM ? bottom : 'auto'"
  [style.top.px]="layoutStyle === appTable.layoutStyle.ABSOLUTE_WITH_TOP_BOTTOM ? top : 'auto'"
  class="app-table"
>
  <qima-table [qimaTableIsStriped]="isStriped">
    <qima-table-head
      *ngIf="isHeadVisible"
      [ngClass]="{ 'app-table__fix-header': isHeaderFix }"
    >
      <div
        #stickyLeftRef
        *ngIf="stickyLeftHeaders.length"
        [style.min-width.px]="stickyLeftHeadersWidth"
        class="app-table__columns-sticky-left qima-table-head-cell"
      >
        <ng-container
          *ngFor="let header of stickyLeftHeaders; let index = index"
          appNgForTrackByUuid
        >
          <qima-table-head-cell-checkbox
            (qimaTableHeadCellCheckboxClick)="onHeadCellCheckboxClick(index)"
            *ngIf="header.type === appTableDataCellService.tableDataType.CHECKBOX"
            [qimaTableHeadCellCheckboxState]="headCellCheckboxState"
          ></qima-table-head-cell-checkbox>
          <qima-table-head-cell
            (qimaTableHeadCellSortAscendingChange)="onSortAscendingChange($event, header.sortKey || header.key || '')"
            *ngIf="header.type !== appTableDataCellService.tableDataType.CHECKBOX && !header.iconName"
            [qimaTableHeadCellLabel]="header.label | translate"
            [qimaTableHeadCellSort]="header.sortType ?? undefined"
            [qimaTableHeadCellTooltip]="header.tooltip || '' | translate"
            [style.width.px]="header?.width"
          ></qima-table-head-cell>
          <ng-container *ngTemplateOutlet="headerIcon; context: { header }"></ng-container>
        </ng-container>
      </div>
      <ng-container
        *ngFor="let header of renderedTableHeaders; let index = index"
        appNgForTrackByUuid
      >
        <qima-table-head-cell-checkbox
          (qimaTableHeadCellCheckboxClick)="onHeadCellCheckboxClick(index)"
          *ngIf="header.type === appTableDataCellService.tableDataType.CHECKBOX"
          [qimaTableHeadCellCheckboxState]="headCellCheckboxState"
        ></qima-table-head-cell-checkbox>
        <qima-table-head-cell
          (qimaTableHeadCellSortAscendingChange)="onSortAscendingChange($event, header.sortKey || header.key || '')"
          *ngIf="header.type !== appTableDataCellService.tableDataType.CHECKBOX && !header.iconName"
          [qimaTableHeadCellLabel]="header.label | translate"
          [qimaTableHeadCellSort]="header.sortType ?? undefined"
          [qimaTableHeadCellTooltip]="header.tooltip || '' | translate"
          [style.width.px]="header?.width"
        ></qima-table-head-cell>
        <ng-container *ngTemplateOutlet="headerIcon; context: { header }"></ng-container>
      </ng-container>
      <div
        #stickyRightRef
        *ngIf="stickyRightHeaders.length"
        [style.min-width.px]="stickyRightHeadersWidth"
        class="app-table__columns-sticky-right qima-table-head-cell"
      >
        <ng-container
          *ngFor="let header of stickyRightHeaders; let index = index"
          appNgForTrackByUuid
        >
          <qima-table-head-cell-checkbox
            (qimaTableHeadCellCheckboxClick)="onHeadCellCheckboxClick(index)"
            *ngIf="header.type === appTableDataCellService.tableDataType.CHECKBOX"
            [qimaTableHeadCellCheckboxState]="headCellCheckboxState"
          ></qima-table-head-cell-checkbox>
          <qima-table-head-cell
            (qimaTableHeadCellSortAscendingChange)="onSortAscendingChange($event, header.sortKey || header.key || '')"
            *ngIf="header.type !== appTableDataCellService.tableDataType.CHECKBOX && !header.iconName"
            [qimaTableHeadCellLabel]="header.label | translate"
            [qimaTableHeadCellSort]="header.sortType ?? undefined"
            [qimaTableHeadCellTooltip]="header.tooltip || '' | translate"
            [style.width.px]="header?.width"
          ></qima-table-head-cell>
          <ng-container *ngTemplateOutlet="headerIcon; context: { header }"></ng-container>
        </ng-container>
      </div>
    </qima-table-head>
    <qima-table-body>
      <qima-table-row
        (click)="onRowClick(rowIndex, row)"
        (keydown.enter)="onRowEnter(rowIndex, row)"
        *ngFor="let row of tableData; let rowIndex = index"
        [class.app-table__can-click]="canClick"
        [class.app-table__hover]="isHover"
        [class.app-table__select-row]="activeRowIndex === rowIndex && hasSelectedBackgroundColor"
        appNgForTrackByUuid
      >
        <div
          #stickyLeftRef
          *ngIf="row.stickyLeftData?.length"
          class="app-table__columns-sticky-left"
          [class.app-table__columns-sticky-left--striped]="isStriped && rowIndex % 2 === 0"
        >
          <ng-container *ngTemplateOutlet="cells; context: { row, rowData: row.stickyLeftData, rowIndex, headers: stickyLeftHeaders }"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="cells; context: { row, rowData: row.renderedData, rowIndex, headers: renderedTableHeaders }"></ng-container>
        <div
          #stickyRightRef
          *ngIf="row.stickyRightData?.length"
          class="app-table__columns-sticky-right"
          [class.app-table__columns-sticky-right--striped]="isStriped && rowIndex % 2 === 0"
        >
          <ng-container *ngTemplateOutlet="cells; context: { row, rowData: row.stickyRightData, rowIndex, headers: stickyRightHeaders }"></ng-container>
        </div>
      </qima-table-row>
    </qima-table-body>
  </qima-table>
  <app-empty-content
    *ngIf="!tableData.length"
    [emptyContentMessage]="emptyMessage"
  ></app-empty-content>
</div>

<ng-template
  #headerIcon
  let-header="header"
>
  <qima-button-icon
    *ngIf="header.iconName"
    [style.width.px]="header.width"
    class="header-icon"
    [qimaButtonIconName]="header.iconName"
    [qimaButtonIconStyle]="qimaButton.buttonIconStyle.TERTIARY"
    (qimaButtonIconClick)="header.headerClick?.()"
  ></qima-button-icon>
</ng-template>

<ng-template
  #cells
  let-headers="headers"
  let-row="row"
  let-rowData="rowData"
  let-rowIndex="rowIndex"
>
  <ng-container
    *ngFor="let cellData of rowData; let columnIndex = index"
    appNgForTrackByUuid
  >
    <qima-table-body-cell-checkbox
      (click)="onCellCheckboxClick($event)"
      (qimaTableBodyCellCheckboxClick)="onTableBodyCellCheckboxClick($event, cellData, row)"
      *ngIf="cellData.checkState"
      [qimaTableBodyCellCheckboxState]="cellData.checkState"
    ></qima-table-body-cell-checkbox>

    <ng-container *ngIf="!cellData.checkState">
      <app-common-table-cell
        (click)="cellData.cellClick?.($event)"
        (commonTableIconCellClick)="onIconCellClick($event)"
        *ngIf="!cellData.customizedCellConfiguration"
        [commonTableCellData]="cellData"
        [commonTableCellIndex]="rowIndex"
        [commonTableCellOriginData]="row.originData"
        [commonTableCellWidth]="(headers && headers[columnIndex]?.width) || 'auto'"
      ></app-common-table-cell>

      <app-customized-table-cell
        *ngIf="cellData.customizedCellConfiguration"
        [customizedTableCellConfiguration]="cellData.customizedCellConfiguration"
        [customizedTableCellWidth]="(headers && headers[columnIndex]?.width) || 'auto'"
      ></app-customized-table-cell>
    </ng-container>
  </ng-container>
</ng-template>
