import { IOrderFee } from '../../models/interfaces/order-fee.interface';
import { ILabTestingInvoices } from '@aca-new/app/pages/payments/pages/payments-content/shared/models/interfaces/lab-testing-invoice.interface';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { EPaymentMethod } from '@aca-new/app/shared/models/enums/payment-method.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { QimaNullableType } from '@aca-new/app/shared/models/types/qima.type';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class OrderFeeService {
  private readonly _customPaymentTokenKey = 'QIMA-Custom-Payment-Token';
  public constructor(
    private readonly _storageService: StorageService,
    private readonly _httpService: HttpService
  ) {}

  public getOrderFees$(orderIds: Array<string>, serviceCategory = '', paymentType: EPaymentMethod = EPaymentMethod.ADYEN): Observable<QimaNullableType<IOrderFee[]>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const params = new URLSearchParams({ orderIds: orderIds.join(','), serviceCategory });
    const url = `${SERVER_URL}/user/${userId}/payment-type/${paymentType}/payment?${params.toString()}`;

    return this._httpService.httpClient.get<IHttpResponseBody<QimaNullableType<IOrderFee[]>>>(url, { observe: 'response' }).pipe(
      map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<IOrderFee[]>>>>): QimaNullableType<IOrderFee[]> => {
        if (response?.headers?.get(this._customPaymentTokenKey) && paymentType === EPaymentMethod.ADYEN) {
          const token = response?.headers?.get(this._customPaymentTokenKey);

          this._storageService.setItem(EStorageKeys.CUSTOM_PAYMENT_TOKEN, token || '');
        }

        return response?.body?.content || null;
      })
    );
  }

  public getLTOrderFees$(orderIds: Array<string>): Observable<QimaNullableType<ILabTestingInvoices>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const params = new URLSearchParams({ invoiceIdList: orderIds.join(',') });
    const url = `${SERVER_URL}/user/${userId}/lt/paymentType/Adyen/invoices?${params.toString()}`;

    return this._httpService.httpClient.get<IHttpResponseBody<QimaNullableType<ILabTestingInvoices>>>(url, { observe: 'response' }).pipe(
      map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<ILabTestingInvoices>>>>): QimaNullableType<ILabTestingInvoices> => {
        if (response?.headers.get(this._customPaymentTokenKey)) {
          this._storageService.setItem(EStorageKeys.CUSTOM_PAYMENT_TOKEN, response?.headers.get(this._customPaymentTokenKey) || '');
        }

        return response?.body?.content || null;
      })
    );
  }
}
