import { ErrorPageComponent } from '@aca-new/app/shared/components/error-page/error-page.component';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [ErrorPageComponent],
})
export class PageNotFoundComponent {
  public title: string = 'ERROR.PAGE_NOT_FOUND';
  public description: string = 'ERROR.PAGE_NOT_FOUND_DESCRIPTION';
}
