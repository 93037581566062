import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { AppSystemEventService } from '@aca-new/app/shared/services/data-track-services/system-event/app-system-event.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CookieService } from 'ngx-cookie';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AuthoritiesGuard implements CanActivate {
  public constructor(
    private readonly _cookieStorage: CookieService,
    private readonly _storageService: StorageService,
    private readonly _appAuthenticationService: AppAuthenticationService,
    private readonly _appSystemEventService: AppSystemEventService,
    @Inject(WINDOW) private readonly _window: Window
  ) {}

  public canActivate(): boolean | Promise<boolean> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const token = this._cookieStorage.get(EStorageKeys.AUTH_TOKEN);

    if (decodeURIComponent(this._window.location.href).indexOf('mode=aca') !== -1) {
      const tempUrl = decodeURIComponent(this._window.location.href).replace(token ?? '', '');

      this._appSystemEventService
        .sendSystemEvent$({
          appName: 'myQima',
          action: 'myQIMA-in-ACA',
          featureName: 'myQIMA-nest-ACA',
          sourceId: `userId-${userId}`,
          sourceType: 'myQIMA-nest-ACA',
          customField1: `${tempUrl.substring(0, 500)}`,
        })
        .pipe(untilDestroyed(this))
        .subscribe();
    }

    if (userId && token) {
      return true;
    }

    this._appAuthenticationService.navigateToLogin();

    return false;
  }
}
