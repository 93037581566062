import { MARKETING_WEBSITE_URL } from '@aca-new/app/shared/constants/app.constants';
import { IMAGES } from '@aca-new/app/shared/constants/images.constants';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QimaButtonModule } from '@qima/ngx-qima';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [QimaButtonModule, TranslateModule, CommonModule],
})
export class ErrorPageComponent {
  /**
   * @description
   * Image for error page.
   * @type {string}
   * @default 'IMAGES.pageNotFoundBig'
   */
  @Input('errorPageImage')
  public image: string = IMAGES.pageNotFound;

  /**
   * @description
   * Title for error page.
   * @type {string}
   * @default ''
   */
  @Input('errorPageTitle')
  public title: string = '';

  /**
   * @description
   * Description for error page.
   * @type {string}
   * @default ''
   */
  @Input('errorPageDescription')
  public description: string = '';

  /**
   * @description
   * Go back home button is visible or not.
   * @type {boolean}
   * @default 'true'
   */
  @Input('errorPageIsGoBackHomeVisible')
  public isGoBackHomeVisible: boolean = true;

  @Output('errorPageGoBackHomeClick')
  public readonly goBackHomeClick: EventEmitter<void> = new EventEmitter<void>();

  public constructor(
    public readonly qimaButton: QimaButtonService,
    @Inject(WINDOW) private readonly _window: IMyWindow
  ) {}

  public onContactUsClick(): void {
    this._window.open(`${MARKETING_WEBSITE_URL}/contact-us`, '_blank');
  }
}
