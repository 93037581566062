import { ILabTestingInvoices } from '@aca-new/app/pages/payments/pages/payments-content/shared/models/interfaces/lab-testing-invoice.interface';
import { IOrderFee } from '@aca-new/app/pages/payments/pages/payments-content/shared/models/interfaces/order-fee.interface';
import { OrderFeeService } from '@aca-new/app/pages/payments/pages/payments-content/shared/services/order-fee/order-fee.service';
import { PayByTransferDetailsComponent } from '@aca-new/app/pages/payments/shared/components/pay-by-transfer/shared/components/pay-by-transfer-details/pay-by-transfer-details.component';
import { PaymentServiceType } from '@aca-new/app/pages/payments/shared/models/types/payment-service-type.type';
import { LtPaymentService } from '@aca-new/app/pages/payments/shared/services/lt/lt-payment.service';
import { ReflowService } from '@aca-new/app/pages/reflow/shared/services/reflow.service';
import { ClipboardComponent } from '@aca-new/app/shared/components/clipboard/clipboard.component';
import { CurrencyViewModule } from '@aca-new/app/shared/components/currency-view/currency-view.module';
import { PageItemType } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-data.interface';
import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { DialogHeaderModule } from '@aca-new/app/shared/components/modal/shared/components/dialog-header/dialog-header.module';
import { DIALOG_DATA } from '@aca-new/app/shared/components/modal/shared/tokens/dialog.token';
import { ITableBodyRow } from '@aca-new/app/shared/components/table/shared/interfaces/table-data.interface';
import { TrackByUuidDirective } from '@aca-new/app/shared/directives/track-by-uuid.directive';
import { IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { IParameter } from '@aca-new/app/shared/models/interfaces/parameter.interface';
import { QimaNullableType } from '@aca-new/app/shared/models/types/qima.type';
import { AppParamsService } from '@aca-new/app/shared/services/book-services/app-params/app-params.service';
import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { QimaIconService } from '@aca-new/app/shared/services/exported-services/qima-icon/qima-icon.service';
import { QimaTooltipService } from '@aca-new/app/shared/services/exported-services/qima-tooltip/qima-tooltip.service';
import { AppFileDownloadService } from '@aca-new/app/shared/services/file-services/app-file-download.service';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { AppSnackbarService } from '@aca-new/app/shared/services/modal-services/app-snackbar/app-snackbar.service';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntilDestroy,
  untilDestroyed,
} from '@ngneat/until-destroy';
import {
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {
  EQimaMimeType,
  EQimaSnackbarType,
  IQimaFile,
  QimaButtonModule,
  QimaFileModule,
  QimaInputFileSelectModule,
  QimaOptionalType,
  QimaTooltipDirectiveModule,
} from '@qima/ngx-qima';
import {
  Observable,
  of,
} from 'rxjs';

interface IOrderPrice {
  cost: number;
  id: string;
  orderId?: string;
  rfqNumber: string;
}

interface IPaymentDialogData {
  isLT?: boolean;
  orderId?: string;
  orderIds?: string[];
  orders?: ITableBodyRow[];
  paymentService?: PaymentServiceType;
  serviceType?: string;
}

interface IBankInfo {
  canCopy: boolean;
  key: string;
  value: string;
}

@UntilDestroy()
@Component({
  selector: 'app-payment-by-transfer',
  templateUrl: './pay-by-transfer.component.html',
  styleUrls: ['./pay-by-transfer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [ReflowService, OrderFeeService],
  imports: [
    CommonModule,
    ClipboardComponent,
    DialogHeaderModule,
    TranslateModule,
    QimaTooltipDirectiveModule,
    TrackByUuidDirective,
    QimaInputFileSelectModule,
    QimaFileModule,
    QimaButtonModule,
    CurrencyViewModule,
    PayByTransferDetailsComponent,
  ],
})
export class PayByTransferComponent implements OnInit {
  public orderFees: IOrderFee[] = [];
  public totalFees: number = 0;
  public totalFeesUnit: string = 'USD';
  public uploadFiles: IQimaFile[] = [];
  public paypalOrderIds: string[] = [];
  public fileSelectAccept = [EQimaMimeType.IMAGE_PNG, EQimaMimeType.IMAGE_JPG, EQimaMimeType.IMAGE_JPEG, EQimaMimeType.APPLICATION_PDF];
  public currentPaymentService: PaymentServiceType | undefined = undefined;
  public readonly qimaMimeType = EQimaMimeType;
  public bankInfo: IBankInfo[] = [];
  private readonly _orderIdToRefMap: Record<string, PageItemType> = {};

  public constructor(
    private readonly _dialogRef: DialogRef<string>,
    public readonly qimaButton: QimaButtonService,
    public readonly qimaTooltip: QimaTooltipService,
    public readonly qimaIcon: QimaIconService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    @Inject(DIALOG_DATA) private readonly _orderPayDialogData: IPaymentDialogData,
    private readonly _overlayService: AppOverlayService,
    private readonly _translateService: TranslateService,
    private readonly _appSnackbarService: AppSnackbarService,
    private readonly _appParamsService: AppParamsService,
    private readonly _appFileDownloadService: AppFileDownloadService
  ) {}

  public ngOnInit(): void {
    this._initData();
  }

  public close(): void {
    this._dialogRef.close('');
  }

  public onPdfDownloadClick(): void {
    this._appFileDownloadService.downloadDirectly('HK04 QIMA Limited 043-244268-838 - Bank Reference Letter 31Jul24.pdf', '4C83A88A698F46B1AF0D56F777280EC8');
  }

  public onFileSelectFilesChange(files: IQimaFile[]): void {
    const totalSize = this.uploadFiles.concat(files).reduce((total, file): number => {
      return total + file.size;
    }, 0);

    if (totalSize > 40 * 1024 * 1024) {
      this._appSnackbarService.showSnackbar(this._translateService.instant('PAY_BY_TRANSFER.UPLOAD_FILES_OVER_LIMIT') as string, EQimaSnackbarType.WARNING, 2000);

      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (!this.fileSelectAccept.includes(files[i].type as EQimaMimeType)) {
        this._appSnackbarService.showSnackbar(
          this._translateService.instant('FILE_UPLOAD.FILE_REJECTED', { name: files[i].name ?? '' }) as string,
          EQimaSnackbarType.WARNING,
          2000
        );

        return;
      }
    }

    if (this.uploadFiles.length > 0) {
      this.uploadFiles = this.uploadFiles.concat(files);
    } else {
      this.uploadFiles = files;
    }

    this._changeDetectorRef.markForCheck();
  }

  public onRemoveClick(_file: IQimaFile, idx: number): void {
    this.uploadFiles.splice(idx, 1);
    this._changeDetectorRef.markForCheck();
  }

  public onSubmit(): void {
    if (!this.currentPaymentService) {
      return;
    }

    const { orders } = this._orderPayDialogData;

    this._overlayService.updateIsLoading(true);
    this.currentPaymentService
      .payByTransfer$(this.paypalOrderIds, this.orderFees, this.uploadFiles, orders)
      .pipe(untilDestroyed(this))
      .subscribe((_res: IHttpResponseBody<undefined>): void => {
        this._overlayService.updateIsLoading(false);
        this._dialogRef.close(this._translateService.instant('PAY_BY_TRANSFER.UPLOAD_SLIP_SUCCESS') as string);
      });
  }

  private _initData(): void {
    this._initPaypalOrderIds();
    this._getOrderFees();
    this._getBankInfo();
  }

  private _getBankInfo(): void {
    this._appParamsService
      .getParameterByClassificationAndKey$('ADVANCE_PAYMENT', 'PAY_BY_TRANSFER_BANK_INFO')
      .pipe(untilDestroyed(this))
      .subscribe((res: QimaNullableType<IParameter>): void => {
        if (!res) {
          return;
        }

        const dataSource = JSON.parse(res.value);

        this.bankInfo = [
          { canCopy: false, key: 'PAY_BY_TRANSFER.BANK_NAME', value: dataSource['Bank Name'] },
          { canCopy: true, key: 'PAY_BY_TRANSFER.ACCOUNT_NUMBER', value: dataSource['Account Number'] },
          { canCopy: true, key: 'PAY_BY_TRANSFER.SWIFT', value: dataSource['SWIFT'] },
          { canCopy: true, key: 'PAY_BY_TRANSFER.BANK_CODE', value: dataSource['Bank Code'] },
          { canCopy: false, key: 'PAY_BY_TRANSFER.ACCOUNT_NAME', value: dataSource['Account Name'] },
          { canCopy: false, key: 'PAY_BY_TRANSFER.BANK_ADDRESS', value: dataSource['Bank Address'] },
        ];
        this._changeDetectorRef.markForCheck();
      });
  }

  private _initPaypalOrderIds(): void {
    const { orders, orderId } = this._orderPayDialogData;

    this.currentPaymentService = this._orderPayDialogData.paymentService;

    if (orders?.length) {
      this.paypalOrderIds = orders.map((item): string => {
        const orderData = item.originData as IOrderPrice;

        if (this._orderPayDialogData.paymentService instanceof LtPaymentService) {
          return orderData.id;
        }

        this._orderIdToRefMap[orderData.id] = orderData.rfqNumber;

        return orderData.orderId ?? orderData.id;
      });
    } else if (orderId) {
      this.paypalOrderIds = [orderId];
    }
  }

  private _getOrderFees(): void {
    this._overlayService.updateIsLoading(true);

    this._watchOrderFees$()
      .pipe(untilDestroyed(this))
      .subscribe((result: QimaOptionalType<IOrderFee[] | ILabTestingInvoices>): void => {
        this.orderFees = result as unknown as IOrderFee[];
        this._setTotalFees();
        this._overlayService.updateIsLoading(false);
        this._changeDetectorRef.markForCheck();
      });
  }

  private _watchOrderFees$(): Observable<QimaOptionalType<IOrderFee[] | ILabTestingInvoices>> {
    if (!this.currentPaymentService) {
      return of(undefined);
    }

    return this.currentPaymentService.getOrderFees$(this.paypalOrderIds, this.orderFees, this._orderIdToRefMap);
  }

  private _setTotalFees(): void {
    this.totalFees = this.orderFees?.reduce((acc: number, fee: IOrderFee): number => {
      this.totalFeesUnit = fee.amountUnit ?? 'USD';

      let subFees = 0;

      if (fee.fees) {
        subFees = fee.fees.reduce((subTotal: number, subFee: IOrderFee): number => {
          return subTotal + parseFloat(subFee.amount);
        }, 0);
      }

      if (fee.itemName === 'ADYEN fees') {
        return acc + subFees;
      }

      return acc + parseFloat(fee.amount) + subFees;
    }, 0);
  }
}
