import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { timeRangeValidator } from '@aca-new/app/shared/components/modal/shared/components/dialog-export/shared/validators/time-range.validator';
import { DialogExportService, IExport } from '@aca-new/app/shared/components/modal/shared/services/dialog-export.service';
import { DIALOG_DATA } from '@aca-new/app/shared/components/modal/shared/tokens/dialog.token';
import { AppFormService } from '@aca-new/app/shared/services/exported-services/app-form/app-form.service';
import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { QimaInputService } from '@aca-new/app/shared/services/exported-services/qima-input/qima-input.service';
import { AppFileDownloadService } from '@aca-new/app/shared/services/file-services/app-file-download.service';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { AppSnackbarService } from '@aca-new/app/shared/services/modal-services/app-snackbar/app-snackbar.service';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EQimaBlockBannerType, EQimaSnackbarType, QimaDateType } from '@qima/ngx-qima';
import dayjs from 'dayjs';

interface IDialogExportData {
  filename: string | undefined;
  title: string;
  url: string;
}

@UntilDestroy()
@Component({
  selector: 'app-dialog-export',
  templateUrl: './dialog-export.component.html',
  styleUrls: ['./dialog-export.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogExportService],
})
export class DialogExportComponent<T> {
  public endMinimumDate: Date | undefined = undefined;
  public readonly qimaBlockBannerType = EQimaBlockBannerType.WARNING;

  public form = new FormGroup(
    {
      startDate: new FormControl<QimaDateType>('', Validators.required),
      endDate: new FormControl<QimaDateType>('', Validators.required),
    },
    { validators: timeRangeValidator }
  );

  public constructor(
    @Inject(DialogRef) private readonly _dialogRef: DialogRef<T>,
    @Inject(DIALOG_DATA) public readonly dialogData: IDialogExportData,
    private readonly _appSnackbarService: AppSnackbarService,
    private readonly _dialogExportService: DialogExportService,
    private readonly _overlayService: AppOverlayService,
    private readonly _fileDownloadService: AppFileDownloadService,
    public readonly qimaInput: QimaInputService,
    public readonly qimaButton: QimaButtonService,
    public readonly appForm: AppFormService
  ) {}

  public close(): void {
    this._dialogRef.close();
  }

  public startDateChange(date: unknown): void {
    this.endMinimumDate = date as Date;
  }

  public export(): void {
    const appSnackbarService = this._appSnackbarService;

    if (this.form.value.startDate && this.form.value.endDate) {
      const startDate = dayjs(this.form.value.startDate).format('YYYY-MM-DD');
      const endDate = dayjs(this.form.value.endDate).format('YYYY-MM-DD');
      const url = this.dialogData.url.replace('{start}', startDate).replace('{end}', endDate);

      this._overlayService.updateIsLoading(true);
      this._dialogExportService
        .export$(url)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (res: IExport): void => {
            if (!res?.xlsx_base64) {
              appSnackbarService.showSnackbar('ERROR.EMPTY_DATA', EQimaSnackbarType.WARNING);
              this._overlayService.updateIsLoading(false);

              return;
            }

            // TODO QIMA should be a common name
            let fileName = `QIMA Orders List(${startDate} - ${endDate}).xlsx`;

            if (this.dialogData.filename) {
              fileName = this.dialogData.filename.replace('{startDate}', startDate).replace('{endDate}', endDate);
            }

            this._fileDownloadService.downloadBase64(fileName, res.xlsx_base64);
            this._overlayService.updateIsLoading(false);
            this._dialogRef.close();
          },
          error(): void {
            appSnackbarService.showSnackbar('ERROR.EXPORT_ERROR', EQimaSnackbarType.ERROR);
          },
        });
    }
  }
}
