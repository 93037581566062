import { AI_API_ACCESS_TOKEN } from '@aca-new/app/shared/constants/ai-api-access-token.constants';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { ETargetType } from '@aca-new/app/shared/models/enums/target-type.enum';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { Inject, Injectable } from '@angular/core';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment-timezone';
import { CookieService } from 'ngx-cookie';
import { Observable, Subject } from 'rxjs';

// TODO merge with app-file
@Injectable({
  providedIn: 'root',
})
export class AppFileDownloadService {
  public constructor(
    @Inject(WINDOW) private readonly _window: IMyWindow,
    private readonly _cookieStorage: CookieService,
    private readonly _storageService: StorageService,
    private readonly _overlayService: AppOverlayService
  ) {}

  public downloadBase64(fileName: string, base64: string): void {
    const url = `data:application/octet-stream;base64,${base64}`;

    this.downloadFile(fileName, url);
  }

  public downloadByFileId(fileName: string, fileId: string): void {
    this._overlayService.updateIsLoading(true);
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    let url = `${SERVER_URL}/user/${userId}/file/${fileId}`;

    if (fileName) {
      url += `?fileName=${fileName}`;
    }

    this.downloadFile(fileName, url);
    this._overlayService.updateIsLoading(false);
  }

  public downloadDirectly(fileName: string, fileId: string, isQualityManual: boolean = false): void {
    const token = this._cookieStorage.get(EStorageKeys.AUTH_TOKEN) ?? '';
    const last50Token: string = token.substring(token.length - 50);
    const sessionId = this._cookieStorage.get(EStorageKeys.SESSION_ID);
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    let url: string = `${SERVER_URL}/user/${userId}/file/${fileId}/session/${sessionId}?code=${last50Token}`;

    if (isQualityManual) {
      url = `${SERVER_URL}/user/${userId}/quality-manual?sessionId=${sessionId}&code=${last50Token}`;
    }

    this.downloadFile(fileName, url);
  }

  public base64ToArrayBuffer(base64: string): Uint8Array {
    const binaryString: string = this._window.atob(base64);
    const binaryLen: number = binaryString.length;
    const bytes: Uint8Array = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
  }

  public fetchBlob(blob: Iterable<number>, fileName: string): void {
    const base64 = btoa(
      new Uint8Array(blob).reduce((data: string, byte: number): string => {
        // eslint-disable-next-line no-restricted-globals
        return data + String.fromCharCode(byte).toString();
      }, '')
    );
    const finalBlob: Blob = new Blob([this.base64ToArrayBuffer(base64)], {
      type: 'octet/stream',
    });
    const url = URL.createObjectURL(finalBlob);

    this.downloadFile(fileName, url);
  }

  public downloadViaBlob$(url: string, zipName: string | null, params: string[], headers?: { [key: string]: string | null }): Observable<boolean> {
    this._overlayService.updateIsLoading(true);
    let contentType: string = 'application/octet-stream';
    const isPostMethod: boolean = params.length > 0;

    if (isPostMethod) {
      contentType = 'application/json';
    }

    const xhr: XMLHttpRequest = new XMLHttpRequest();
    const token = this._cookieStorage.get(EStorageKeys.AUTH_TOKEN);

    xhr.open(isPostMethod ? 'POST' : 'GET', url, true);
    xhr.responseType = 'arraybuffer';

    // Set customized headers, override default ones if exists
    const requestHeaders = Object.assign(
      {
        'authorization': `Bearer ${token}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'ai-api-access-token': AI_API_ACCESS_TOKEN,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': contentType,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Disposition': `attachment; filename="${zipName}"`,
      },
      headers ?? {}
    );

    Object.entries(requestHeaders).forEach(([key, value]): void => {
      if (value) {
        xhr.setRequestHeader(key, value);
      }
    });

    const subject$ = new Subject<boolean>();

    xhr.onload = (): void => {
      if (xhr.status === StatusCodes.OK) {
        this.fetchBlob(xhr.response as Iterable<number>, zipName || '');
        subject$.next(true);
      } else {
        subject$.next(false);
      }

      this._overlayService.updateIsLoading(false);
    };

    if (isPostMethod) {
      xhr.send(JSON.stringify(params));
    } else {
      xhr.send();
    }

    return subject$;
  }

  public downloadFilesByZip$(
    ids: string,
    orderNo: string,
    fileName: string | null,
    isPost?: boolean,
    headers?: { [key: string]: string | null },
    overrideUserId?: string
  ): Observable<boolean> {
    const userId = overrideUserId ?? this._storageService.getItem(EStorageKeys.USER_ID);
    let zipName: string | null = fileName;

    // multiple files should use zip suffix
    if (ids.indexOf(',') > -1) {
      zipName = fileName || `${orderNo} Attachments ${moment().format('DD-MMM-YYYY')}.zip`;
    }

    let params: string[] = [];
    let url: string = `${SERVER_URL}/user/${userId}/files-in-zip/${ids}`;

    if (isPost) {
      url = `${SERVER_URL}/user/${userId}/files-in-zip/`;
      params = ids.split(',');
    }

    return this.downloadViaBlob$(url, zipName, params, headers);
  }

  public downloadFile(fileName: string, url: string, target: ETargetType = ETargetType.BLANK): void {
    if (!url) {
      return;
    }

    const a = document.createElement('a');

    a.id = 'anchor-for-download-file';
    document.body.appendChild(a);
    a.className = 'downloader';
    a.target = target;

    a.href = url;
    a.download = fileName;
    a.click();
    document.getElementById('anchor-for-download-file')?.remove();
  }

  public downloadFileByBlob(blob: Blob, filename: string): void {
    const link = document.createElement('a');

    link.href = this._window.URL.createObjectURL(blob);
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this._window.URL.revokeObjectURL(link.href);
  }
}
