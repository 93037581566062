import { EServiceType } from '@aca-new/app/shared/models/enums/service-type.enum';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serviceTypeFullName',
  pure: true,
})
export class ServiceTypeFullNamePipe implements PipeTransform {
  public transform(serviceType: string): string {
    switch (serviceType.toUpperCase()) {
      case EServiceType.CLC:
        return 'APP_ASIDE.ASIDE_MENU.CONTAINER_LOADING_CHECK';
      case EServiceType.CTPAT:
      case 'CTPAT':
        return 'APP_ASIDE.ASIDE_MENU.C_TPAT';
      case EServiceType.DR:
        return 'APP_ASIDE.ASIDE_MENU.DESKTOP_REVIEW';
      case EServiceType.DUPRO:
        return 'APP_ASIDE.ASIDE_MENU.DURING_PRODUCTION_INSPECTION';
      case EServiceType.EA:
        return 'APP_ASIDE.ASIDE_MENU.ETHICAL_AUDIT';
      case EServiceType.ENVA:
        return 'APP_ASIDE.ASIDE_MENU.ENVIRONMENTAL_AUDIT';
      case EServiceType.GHP:
        return 'APP_ASIDE.ASIDE_MENU.GOOD_HYGIENE';
      case EServiceType.GMARK:
      case 'G-MARK':
        return 'APP_ASIDE.ASIDE_MENU.G_MARK';
      case EServiceType.GMP:
        return 'APP_ASIDE.ASIDE_MENU.GOOD_MANUFACTURING';
      case EServiceType.PSI:
        return 'APP_ASIDE.ASIDE_MENU.PRE_SHIPMENT_INSPECTION';
      case EServiceType.IPC:
        return 'APP_ASIDE.ASIDE_MENU.INITIAL_PRODUCTION_CHECK';
      case EServiceType.LT:
        return 'APP_ASIDE.ASIDE_MENU.BOOK_LAB_TEST';
      case EServiceType.MA:
        return 'APP_ASIDE.ASIDE_MENU.MANUFACTURING';
      case EServiceType.PEO:
        return 'APP_ASIDE.ASIDE_MENU.PRE_CUSTOMER_CLEARANCE_INSPECTION';
      case EServiceType.PM:
        return 'APP_ASIDE.ASIDE_MENU.PRODUCTION_MONITORING';
      case EServiceType.SABER:
        return 'APP_ASIDE.ASIDE_MENU.SABER_CERTIFICATION';
      case EServiceType.SASOREGISTRATION:
      case 'SASO REGISTRATION':
      case EServiceType.SASO:
        return 'APP_ASIDE.ASIDE_MENU.SASO_REGISTRATION';
      case EServiceType.SC:
        return 'APP_ASIDE.ASIDE_MENU.SAMPLE_COLLECTION';
      case EServiceType.SR:
        return 'APP_ASIDE.ASIDE_MENU.SAMPLE_REVIEW';
      case EServiceType.STRA:
        return 'APP_ASIDE.ASIDE_MENU.STRUCTURAL_AUDIT';
      case EServiceType.TSM:
        return 'APP_ASIDE.ASIDE_MENU.TRANSLATION_SERVICE_MANAGEMENT';
      case EServiceType.STS:
        return 'APP_ASIDE.ASIDE_MENU.SUPPLIER_TRAINING_SESSION';
      case EServiceType.WCP:
      case EServiceType.CSM:
        return 'APP_ASIDE.ASIDE_MENU.WORKER_CALLBACK_PROGRAM';
      case EServiceType.SAQ:
        return 'APP_ASIDE.ASIDE_MENU.SELF_ASSESSMENT_QUESTIONNAIRE';
      case EServiceType.DN:
        return 'DEBIT_NOTES.TITLE';
      default:
        return 'APP_ASIDE.ASIDE_MENU.UNKNOWN_SERVICE_TYPE';
    }
  }
}
