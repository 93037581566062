<div class="user-guide">
  <app-dialog-common dialogCommonTitle="USER_GUIDE.TITLE">
    <div class="content">
      <div class="user-guide__container">
        <img
          alt="user-guide"
          class="user-guide__container__video"
          [src]="helpGuidePicture"
        />

        <div>
          <div
            class="user-guide__container__description"
            [innerHTML]="'USER_GUIDE.DESCRIPTION' | translate"
          ></div>
          <qima-button
            [qimaButtonLabel]="'USER_GUIDE.DOWNLOAD_PDF' | translate"
            (qimaButtonClick)="onDownloadPdfClick()"
            [qimaButtonIconName]="qimaIcon.iconName.DOWNLOAD_PDF"
            [qimaButtonIconPosition]="qimaButton.buttonIconPosition.LEFT"
            [qimaButtonStyle]="qimaButton.buttonStyle.PRIMARY"
            [qimaButtonSize]="qimaButton.buttonSize.LARGE"
          ></qima-button>
        </div>
      </div>
      <img
        alt="plank"
        class="user-guide__plank"
        [src]="plankPicture"
      />
      <div class="user-guide__terms">
        <a
          class="user-guide__terms__term"
          target="_blank"
          [href]="termsConditionsLink"
          >{{ 'COMMON.TERMS_CONDITIONS' | translate }}</a
        >
        <div class="user-guide__terms__divider"></div>
        <a
          class="user-guide__terms__privicy"
          target="_blank"
          [href]="privicyPolicyLink"
          >{{ 'COMMON.PRIVACY_POLICY' | translate }}</a
        >
      </div>
    </div>
  </app-dialog-common>
</div>
