import { EPayFrom } from '@aca-new/app/pages/payment-checkout/shared/models/enums/pay-from.enum';
import { PayByTransferService } from '@aca-new/app/pages/payments/shared/components/pay-by-transfer/shared/services/pay-by-transfer.service';
import { IPostMessageData } from '@aca-new/app/shared/interfaces/post-message.interface';
import { EAvailablePath } from '@aca-new/app/shared/models/enums/available-path.enum';
import { EPostMessageType } from '@aca-new/app/shared/models/enums/post-message-type.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { AppLocationService } from '@aca-new/app/shared/services/browser-services/app-location/app-location-service';
import { AppSystemEventService } from '@aca-new/app/shared/services/data-track-services/system-event/app-system-event.service';
import { AppPostMessageService } from '@aca-new/app/shared/services/post-message-services/app-post-message/app-post-message.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { ENVIRONMENT } from '@aca-new/environments/environment';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { qimaEnumValuesToArray } from '@qima/ngx-qima';
import localForage from 'localforage';
import { Observable, Subject } from 'rxjs';

declare let ENV_TYPE: 'dev' | 'pp' | 'prod';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class AcaMessageService {
  public isAcaFrameAvailable = true;
  public isAcaFrameRendered = false;
  private readonly _companyLogoSubject$: Subject<void> = new Subject<void>();
  private readonly _acaUrl = this._appLocationService.getAcaUrl();

  public constructor(
    private readonly _appPostMessageService: AppPostMessageService,
    private readonly _appLocationService: AppLocationService,
    private readonly _router: Router,
    private readonly _payByTransferService: PayByTransferService,
    private readonly _storageService: StorageService,
    private readonly _translateService: TranslateService,
    private readonly _appUserSettingService: AppUserSettingService,
    private readonly _appSystemEventService: AppSystemEventService,
    @Inject(WINDOW) private readonly _window: Window,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  public isMyQimaPageAvailable(path: string): boolean {
    if (!this.isAcaFrameAvailable) {
      return true;
    }

    if ((this._appUserSettingService.getIsSuperMaster() && this._appUserSettingService.getAccessDashboardOnly()) || this._appUserSettingService.getIsQimaProduce()) {
      this.isAcaFrameAvailable = false;

      return true;
    }

    return qimaEnumValuesToArray(EAvailablePath).some((availablePath: EAvailablePath): boolean => path.startsWith(availablePath));
  }

  public canAcaRouteTo(path: string): boolean {
    const paths = [EAvailablePath.NETWORK, EAvailablePath.PRODUCE_REPORTS, EAvailablePath.SUPPLIER_DOCUMENTS];

    return !paths.some((availablePath: EAvailablePath): boolean => path.startsWith(availablePath));
  }

  public watchMessageFromAca(): void {
    this._appPostMessageService.addHandler('acaMessager', (event): void => {
      if (this._acaUrl.includes(event.origin)) {
        this._messageHandler(event.data as IPostMessageData);
      }
    });
  }

  public informAcaPathChange(target: string, action: string = ''): void {
    this.postMessageToAca({ type: EPostMessageType.PATH_CHANGE, data: { path: target, action } });
  }

  public postMessageToAca(message: IPostMessageData): void {
    const contentWindow = this._getAcaFrame()?.contentWindow;

    if (contentWindow) {
      this._appPostMessageService.post(contentWindow, message, this._acaUrl);
    }
  }

  public companyLogoChange$(): Observable<void> {
    return this._companyLogoSubject$.asObservable();
  }

  /**
   * Updates the router URL, so that it can be used in iframe.
   * Each key in the replaceMappings object is a segment in the URL that needs to be replaced, and the corresponding value is the new segment to replace it.
   * For example,
   * If the URL contains '/analytics/', it will be replaced with '/dashboard/';
   * If the URL contains '/aca', it will be replaced with an empty string.
   * @param {string} url The router URL that needs to be updated.
   * @returns {string} The updated URL.
   */
  public updateRouterUrl(url: string): string {
    const replaceMappings: Record<string, string> = {
      ['/analytics/']: '/dashboard/',
      ['/aca']: '',
      ['/home']: '',
      ['/produce-reports']: '',
    };

    for (const oldSegment in replaceMappings) {
      if (Object.prototype.hasOwnProperty.call(replaceMappings, oldSegment)) {
        const newSegment: string = replaceMappings[oldSegment];

        url = url.replace(oldSegment, newSegment);
      }
    }

    this._appSystemEventService
      .sendSystemEvent$({
        appName: 'myQima',
        action: 'myQIMA-in-ACA',
        featureName: 'AcaMessageService',
        sourceId: 'updateRouterUrl',
        sourceType: 'myQIMA-nest-ACA',
        customField1: 'N/A',
      })
      .pipe(untilDestroyed(this))
      .subscribe();

    return url;
  }

  private _getAcaFrame(): HTMLIFrameElement {
    return this._document.getElementById('acaFrame') as HTMLIFrameElement;
  }

  private _paymentModalShow(data: Record<string, unknown>): void {
    if (!data.orderId) {
      return;
    }

    const queryParams: Record<string, string> = { payFrom: EPayFrom.BOOK, serviceType: data.serviceType as string };

    void this._router.navigate([`/payments/checkout/${data.orderId}`], { queryParams });
  }

  private _pathChange(data: Record<string, unknown>): void {
    this._appSystemEventService
      .sendSystemEvent$({
        appName: 'myQima',
        action: 'myQIMA-in-ACA',
        featureName: 'AcaMessageService',
        sourceId: '_pathChange',
        sourceType: 'myQIMA-nest-ACA',
        customField1: 'N/A',
      })
      .pipe(untilDestroyed(this))
      .subscribe();

    if (!this.isAcaFrameAvailable) {
      return;
    }

    try {
      this._appSystemEventService
        .sendSystemEvent$({
          appName: 'myQima',
          action: 'myQIMA-in-ACA',
          featureName: 'AcaMessageService',
          sourceId: '_pathChange',
          sourceType: 'myQIMA-nest-ACA',
          customField1: 'N/A',
        })
        .pipe(untilDestroyed(this))
        .subscribe();

      let path = data.path as string;

      /**
       * Do not navigate to the book confirmation page
       * because it needs to be open the payment checkout page
       */
      if (path?.includes('/aca/book-confirmation/') || path?.includes('/aca/booking-confirmation/')) {
        if (path.includes('canNavigate=true')) {
          const newPath = path.replace('/aca', '').replace('?canNavigate=true', '').replace('booking-confirmation', 'book-confirmation');

          void this._router.navigateByUrl(newPath);
        }

        return;
      }

      /**
       * Why we need to replace the "step=${number}" to "step-${number}"?
       * On aca side, the path is 'https://aca.qima.com/${router}step=${number}'
       * On MyQIMA side, the '=' is not available in the url, so we use '-' instead.
       * When we want to navigate in MyQIMA, we need to replace the '=' to '-'.
       */
      if (path && /step=\w+/.test(path)) {
        const pathParams = /step=\w+/.exec(path)?.[0] as string;
        const validPathParams = pathParams.replace('=', '-');

        path = path.replace(pathParams, validPathParams);
      }

      if (path.startsWith('/aca/login')) {
        this._window.location.href = `${this._acaUrl}${path.replace('/aca', '')}`;
      }

      if (path.startsWith('/aca/dashboard')) {
        path = path.replace('/aca/dashboard', '/analytics');
      }

      if (path.startsWith('/aca/suppliers/new')) {
        return;
      }

      if (path.startsWith('/aca') && this.isMyQimaPageAvailable(path.split('/aca')[1])) {
        path = path.replace('/aca', '').replace('.', '/');
      }

      if (path.startsWith('/aca/logout')) {
        if (this._window.location.origin.includes('localhost')) {
          this._window.location.href = `${this._acaUrl}/login`;
        } else {
          this._window.location.href = `${ENVIRONMENT[ENV_TYPE].MARKETING_WEBSITE_URL}/logout`;
        }

        this._storageService.clearAuthenticationData();

        return;
      }

      void this._router.navigateByUrl(path);
    } catch (e) {
      console.error(e);
    }
  }

  private _languageChange(data: Record<string, unknown>): void {
    const language = data.language as string;

    void localForage.setItem(EStorageKeys.LOCALE_LANGUAGE, language);
    this._translateService.use(language).pipe(untilDestroyed(this)).subscribe();
  }

  private _informAcaLanguageChange(): void {
    void localForage.getItem(EStorageKeys.LOCALE_LANGUAGE).then((value): void => {
      if (value) {
        this.postMessageToAca({
          type: EPostMessageType.LANGUAGE_CHANGE,
          data: { language: value },
        });
      }
    });
  }

  private _messageHandler(data: IPostMessageData): void {
    if (data.type !== EPostMessageType.ACA_FRAME_RENDERED && !this.isAcaFrameRendered) {
      return;
    }

    switch (data.type) {
      case EPostMessageType.PATH_CHANGE:
        this._updateTitle(data.data as Record<string, string>, data.type);
        this._pathChange(data.data as Record<string, unknown>);
        break;
      case EPostMessageType.LANGUAGE_CHANGE:
        this._languageChange(data.data as Record<string, unknown>);
        break;
      case EPostMessageType.PAYMENT_MODAL_SHOW:
        this._paymentModalShow(data.data as Record<string, unknown>);
        break;
      case EPostMessageType.PAY_BY_TRANSFER_SHOW:
        this._payByTransferService.openPayByTransfer(data.data as Record<string, unknown>);
        break;
      case EPostMessageType.LOAD:
        this._informAcaLanguageChange();
        break;
      case EPostMessageType.RELOAD:
        this._window.location.reload();
        break;
      case EPostMessageType.COMPANY_LOGO_CHANGE:
        this._companyLogoSubject$.next();
        break;
      case EPostMessageType.ACA_FRAME_RENDERED:
        this._updateTitle(data.data as Record<string, string>, data.type);
        this.isAcaFrameRendered = true;
        break;
    }
  }

  private _updateTitle(data: Record<string, string>, type: EPostMessageType): void {
    if (!data?.title) {
      return;
    }

    if (type === EPostMessageType.ACA_FRAME_RENDERED && !this._window.location.href.includes('/aca/')) {
      return;
    }

    this._document.title = data.title;
  }
}
