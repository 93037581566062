import { AsideService } from '@aca-new/app/pages/main/shared/components/aside/shared/services/aside.service';
import { UserGuideComponent } from '@aca-new/app/pages/main/shared/components/header/shared/components/user-guide/user-guide.component';
import { HeaderService } from '@aca-new/app/pages/main/shared/components/header/shared/service/header.service';
import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { IMAGES } from '@aca-new/app/shared/constants/images.constants';
import { QimaNullableType } from '@aca-new/app/shared/models/types/qima.type';
import { QimaAvatarService } from '@aca-new/app/shared/services/exported-services/qima-avatar/qima-avatar.service';
import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { QimaButtonIconService } from '@aca-new/app/shared/services/exported-services/qima-button-icon/qima-button-icon.service';
import { QimaDividerService } from '@aca-new/app/shared/services/exported-services/qima-divider/qima-divider.service';
import { QimaIconService } from '@aca-new/app/shared/services/exported-services/qima-icon/qima-icon.service';
import { QimaTooltipService } from '@aca-new/app/shared/services/exported-services/qima-tooltip/qima-tooltip.service';
import { AcaMessageService } from '@aca-new/app/shared/services/post-message-services/aca-message/aca-message.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EQimaButtonIconStyle, EQimaPosition } from '@qima/ngx-qima';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  public currentUser: IUser | undefined = undefined;
  public companyLogo: string = '';
  public qimaLogo: string = IMAGES.qimaLogo;
  public isProfileMenuVisible: boolean = false;
  public isTranslateMenuVisible: boolean = false;
  public managerAvatar: string = '';
  public tooltipPosition: EQimaPosition.RIGHT = EQimaPosition.RIGHT;
  public tooltipMessage: string = 'APP_ASIDE.ASIDE_MENU.EXPAND_PANEL';
  public translateMenuStyle = EQimaButtonIconStyle.TERTIARY;
  public isUserGuideVisible: boolean = !this._appUserSettingService.getIsQimaProduce();

  public constructor(
    private readonly _headerService: HeaderService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _appAuthenticationService: AppAuthenticationService,
    private readonly _dialog: DialogService,
    private readonly _asideService: AsideService,
    private readonly _appUserSettingService: AppUserSettingService,
    private readonly _router: Router,
    private readonly _acaMessageService: AcaMessageService,
    public readonly qimaAvatar: QimaAvatarService,
    public readonly qimaIcon: QimaIconService,
    public readonly qimaDivider: QimaDividerService,
    public readonly qimaButton: QimaButtonService,
    public readonly qimaTooltip: QimaTooltipService,
    public readonly qimaButtonIcon: QimaButtonIconService
  ) {}

  public ngOnInit(): void {
    this._getUserInfo();
    this.watch();
  }

  public watch(): void {
    this._appAuthenticationService.currentUserSubject$.pipe(untilDestroyed(this)).subscribe((data: Readonly<IUser>): void => {
      this.currentUser = data;
      this._getUserInfo();
      this._changeDetectorRef.markForCheck();
    });

    this._headerService.isProfileMenuVisible$.pipe(untilDestroyed(this)).subscribe((isProfileMenuVisible: boolean): void => {
      this.isProfileMenuVisible = isProfileMenuVisible;

      this._changeDetectorRef.markForCheck();
    });

    this._headerService.isTranslateMenuVisible$.pipe(untilDestroyed(this)).subscribe((isTranslateMenuVisible: boolean): void => {
      this.isTranslateMenuVisible = isTranslateMenuVisible;
      this.translateMenuStyle = this.isTranslateMenuVisible ? EQimaButtonIconStyle.SECONDARY : EQimaButtonIconStyle.TERTIARY_UTILITY;
    });

    this._headerService.companyLogo$.pipe(untilDestroyed(this)).subscribe((logo: string): void => {
      this.companyLogo = logo;
      this._changeDetectorRef.markForCheck();
    });

    this._asideService.isAsideCollapsed$.pipe(untilDestroyed(this)).subscribe((isCollapsed: Readonly<boolean>): void => {
      this.tooltipMessage = isCollapsed ? 'APP_ASIDE.ASIDE_MENU.EXPAND_PANEL' : 'APP_ASIDE.ASIDE_MENU.COLLAPSE_PANEL';
    });

    this._acaMessageService
      .companyLogoChange$()
      .pipe(untilDestroyed(this))
      .subscribe((): void => this._getCompanyLogo());
  }

  public openUserGuide(): void {
    this._dialog.open(UserGuideComponent);
  }

  public openTranslate(event: Readonly<MouseEvent | Event>): void {
    event.stopPropagation();
    this.isTranslateMenuVisible = true;
    this.translateMenuStyle = EQimaButtonIconStyle.SECONDARY;
  }

  public onMenuClick(): void {
    this._asideService.onCollapseMenuClick();
  }

  public onQimaLogoClick(): void {
    if (this._acaMessageService.isAcaFrameAvailable) {
      this._acaMessageService.informAcaPathChange('/home');
    } else {
      void this._router.navigateByUrl('/home');
    }
  }

  public onTranslateOverlayOutsideClick(): void {
    this.isTranslateMenuVisible = false;
    this.translateMenuStyle = EQimaButtonIconStyle.TERTIARY_UTILITY;
  }

  public openProfile(event: Readonly<MouseEvent | Event>): void {
    event.stopPropagation();
    this.isProfileMenuVisible = true;

    this._changeDetectorRef.markForCheck();
  }

  public onProfileOverlayOutsideClick(): void {
    this.isProfileMenuVisible = false;

    this._changeDetectorRef.markForCheck();
  }

  public onProfileOverlayDetach(): void {
    this.isProfileMenuVisible = false;

    this._changeDetectorRef.markForCheck();
  }

  public onTranslateOverlayDetach(): void {
    this.isTranslateMenuVisible = false;

    this._changeDetectorRef.markForCheck();
  }

  private _getUserInfo(): void {
    if (this._appAuthenticationService.isAuthenticated()) {
      this.currentUser = this._appAuthenticationService.getCurrentUser();
      this._getCompanyLogo();
      this._getManagerAvatar();
    }
  }

  private _getCompanyLogo(): void {
    this._headerService
      .getCompanyLogo$()
      .pipe(untilDestroyed(this))
      .subscribe((companyLogo: QimaNullableType<string>): void => {
        if (companyLogo) {
          this.companyLogo = companyLogo;
          this._changeDetectorRef.markForCheck();
        }
      });
  }

  private _getManagerAvatar(): void {
    if (this.currentUser?.company?.id) {
      this._headerService
        .getManagerAvatar$(this.currentUser?.sicId)
        .pipe(untilDestroyed(this))
        .subscribe((managerAvatar: QimaNullableType<string>): void => {
          this.managerAvatar = managerAvatar || IMAGES.managerAvatar;
          this._changeDetectorRef.markForCheck();
        });
    }
  }
}
