import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { IHttpErrorHandler } from '@aca-new/app/shared/interfaces/http-error-handler.interface';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { HttpErrorHandlerService } from '@aca-new/app/shared/services/http-services/http-error-handler/http-error-handler.service';
import { AcaMessageService } from '@aca-new/app/shared/services/post-message-services/aca-message/aca-message.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class ExternalSharedReportHttpErrorHandlerService extends HttpErrorHandlerService implements IHttpErrorHandler {
  public constructor(
    _dialogService: DialogService,
    _appAuthenticationService: AppAuthenticationService,
    _acaMessageService: AcaMessageService,
    _router: Router,
    @Inject(WINDOW) private readonly _window: IMyWindow
  ) {
    super(_dialogService, _appAuthenticationService, _acaMessageService, _router);
  }

  public unauthorized(): void {
    this._window.location.reload();
  }

  public forbidden(_error: Readonly<HttpErrorResponse>): void {
    this._window.location.reload();
  }
}
