import { AbstractControl, ValidationErrors } from '@angular/forms';
import dayjs from 'dayjs';

/**
 * @description
 * Time range validator
 * @param {AbstractControl} control The form control object
 * @returns {ValidationErrors | null} The validation error object
 */
export function timeRangeValidator(control: AbstractControl): ValidationErrors | null {
  const startDate = control.get('startDate');
  const endDate = control.get('endDate');

  return dayjs(endDate?.value as Date).diff(dayjs(startDate?.value as Date), 'years', true) > 1 ? { timeRangeExceedsOneYear: true } : null;
}
