import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import Clipboard from 'clipboard';

@Directive({
  selector: '[appClipboard]',
  standalone: true,
})
export class ClipboardDirective implements OnChanges, OnDestroy {
  /**
   * @description
   * The text to be copied
   * @type {string}
   * @default ''
   */
  @Input('appClipboardText')
  public clipboardText: string = '';

  @Output('appClipboardCopySuccess')
  public readonly copySuccess: EventEmitter<string> = new EventEmitter<string>();

  @Output('appClipboardCopyError')
  public readonly copyError: EventEmitter<void> = new EventEmitter<void>();

  private _clipboard!: Clipboard;

  public constructor(private readonly _el: ElementRef) {}

  public ngOnChanges(_changes: SimpleChanges): void {
    if (this._clipboard) {
      this._clipboard.destroy();
    }

    this._clipboard = new Clipboard(this._el.nativeElement as Element, {
      text: (): string => this.clipboardText,
    });

    this._clipboard.on('success', (e): void => {
      this.copySuccess.emit(e.text);
    });

    this._clipboard.on('error', (): void => {
      this.copyError.emit();
    });
  }

  public ngOnDestroy(): void {
    if (this._clipboard) {
      this._clipboard.destroy();
    }
  }
}
