<div class="app-clipboard">
  <img
    alt="copy"
    qimaTooltip
    appClipboard
    [qimaTooltipPosition]="qimaTooltip.position.RIGHT"
    [qimaTooltipText]="(isCopied ? 'COMMON.COPIED' : 'COMMON.COPY') | translate"
    [qimaTooltipStrategy]="isCopied ? qimaTooltip.strategy.ALWAYS : qimaTooltip.strategy.ON_HOVER"
    [qimaTooltipHasArrow]="true"
    [qimaTooltipStyle]="qimaTooltip.style.LIGHT"
    [appClipboardText]="textToCopy"
    [src]="clipboardIcon"
    (appClipboardCopySuccess)="onCopySuccess()"
    (mouseenter)="onMouseChange()"
    (mouseleave)="onMouseChange()"
  />
</div>
