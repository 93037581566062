import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

/**
 * @description
 * Custom settings control the access to the application
 * @param {ActivatedRouteSnapshot} _route {ActivatedRouteSnapshot} the route
 * @param {RouterStateSnapshot } state the state
 * @returns {boolean} - can access or not
 */
export default function customSettingGuard(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  const appUserSettingService = inject(AppUserSettingService);

  if (appUserSettingService.getIsSuperMaster()) {
    if (appUserSettingService.getAccessDashboardOnly()) {
      if (state.url.startsWith('/analytics')) {
        return true;
      }

      const router = inject(Router);

      void router.navigate(['/analytics']);

      return false;
    }
  }

  return true;
}
