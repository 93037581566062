import { DocumentVerificationErrorHandlerService } from '@aca-new/app/pages/document-verification/shared/services/document-verification-error-handler.service';
import { ExternalSharedReportHttpErrorHandlerService } from '@aca-new/app/pages/reports/pages/external-shared-report/shared/services/external-shared-report-http-error-handler.service';
import { PATHS } from '@aca-new/app/shared/constants/url-path.constants';
import { EMyStatusCode } from '@aca-new/app/shared/models/enums/my-status-code.enum';
import { HttpErrorHandlerService } from '@aca-new/app/shared/services/http-services/http-error-handler/http-error-handler.service';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpMethod } from '@ngneat/spectator';
import { StatusCodes } from 'http-status-codes';
import { catchError, Observable } from 'rxjs';

interface IExcludedRequest {
  method: string;
  url: string;
}

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private readonly _excludeRequest: IExcludedRequest[] = [
    { url: 'tableau', method: HttpMethod.GET },
    { url: 'auth/v2/token', method: HttpMethod.DELETE },
  ];

  public constructor(
    private readonly _router: Router,
    private readonly _overlayService: AppOverlayService,
    private readonly _errorHandlerService: HttpErrorHandlerService,
    private readonly _documentVerificationErrorHandlerService: DocumentVerificationErrorHandlerService,
    private readonly _externalReportHttpErrorHandlerService: ExternalSharedReportHttpErrorHandlerService
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this._excludeRequest.some((config): boolean => request.url.includes(config.url) && request.method === config.method)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse): Observable<never> => {
        this._overlayService.updateIsLoading(false);
        let errorMsg = '';

        if (error.error instanceof ErrorEvent) {
          console.log('this is client side error');
          errorMsg = `Error: ${error.error.message}`;
        } else {
          console.log('this is server side error', error);
          const errorMessage = (error?.error?.msg ?? '') as string;

          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          this._statusCodeHandler(error);
          this._errorHandlerService.setErrorMessage(errorMessage);
        }

        throw new Error(errorMsg);
      })
    );
  }

  private _statusCodeHandler(error: Readonly<HttpErrorResponse>): void {
    switch (error.status) {
      case StatusCodes.UNAUTHORIZED:
        this._getErrorHandler(error).unauthorized();
        break;

      case StatusCodes.FORBIDDEN:
        this._getErrorHandler(error).forbidden(error);
        break;

      case StatusCodes.NOT_FOUND:
        this._getErrorHandler(error).notFound();
        break;

      case StatusCodes.INTERNAL_SERVER_ERROR:
      case EMyStatusCode.FAILED_RESPONSE:
        this._getErrorHandler(error).internalServerError();
        break;
    }
  }

  private _getErrorHandler(error: Readonly<HttpErrorResponse>): HttpErrorHandlerService {
    // For any requests from external shared report page, specific error handler should be used
    if (this._router.url.startsWith(PATHS.externalReport)) {
      return this._externalReportHttpErrorHandlerService;
    }

    if (error.url?.includes(PATHS.documentVerification) || error.url?.includes(PATHS.reportVerification)) {
      return this._documentVerificationErrorHandlerService;
    }

    return this._errorHandlerService;
  }
}
